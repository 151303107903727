import React from "react";
import "./AutoTableRow.css";

export interface IAutoTableRowProps<T> {
    element: T;
    cells: Array<(element: T) => string | number | undefined>;
    onRowClick?: (elementClicked: T) => any;
}

export class AutoTableRow<T> extends React.Component<IAutoTableRowProps<T>> {
    private rowClicked() {
        if (this.props.onRowClick) {
            this.props.onRowClick(this.props.element);
        }
    }

    render() {
        return (
            <tr
                className={
                    "auto-table-row " +
                    (this.props.onRowClick ? "auto-table-hover" : "")
                }
                onClick={() => this.rowClicked()}
                data-test="auto-table-row"
            >
                {this.props.cells.map((cell, index) => (
                    <td
                        key={"auto_table_row_" + index}
                        data-test="auto-table-cell"
                    >
                        {cell(this.props.element)}
                    </td>
                ))}
            </tr>
        );
    }
}
