import React, { ChangeEvent, FormEvent } from "react";
import AsyncState from "../../../shared/enumerations/AsyncState";
import { translate } from "../../../translate/translate";

class MemberSearchForm extends React.Component<{
    searchHandler: (query: string) => void;
    waitState: AsyncState;
}> {
    state = {
        query: "",
        disableSubmit: true
    };

    constructor(props: {
        searchHandler: (query: string) => void;
        waitState: AsyncState;
    }) {
        super(props);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleQueryChange(event: ChangeEvent<HTMLInputElement>) {
        const disableSubmit =
            !event.target.value || event.target.value.length < 2;

        this.setState({
            query: event.target.value,
            disableSubmit: disableSubmit
        });
    }

    handleSearch(event: FormEvent<HTMLFormElement>): boolean {
        event.preventDefault();
        this.props.searchHandler(this.state.query);
        return false;
    }

    render() {
        return (
            <form className="form-inline" onSubmit={this.handleSearch}>
                <div className="form-group mx-sm-3 mb-2">
                    <label htmlFor="memberQueryInput" className="sr-only">
                        {translate.associate.components.searchForm.query}
                    </label>
                    <input
                        type="text"
                        id={"memberQueryInput"}
                        className="form-control"
                        maxLength={20}
                        placeholder={
                            translate.associate.components.searchForm.query
                        }
                        size={30}
                        onChange={this.handleQueryChange}
                        value={this.state.query}
                        disabled={this.props.waitState === AsyncState.Loading}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary mb-2"
                    disabled={
                        this.state.disableSubmit ||
                        this.props.waitState === AsyncState.Loading
                    }
                >
                    {translate.associate.components.searchForm.submitButton}
                </button>
            </form>
        );
    }
}

export default MemberSearchForm;
