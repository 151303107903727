import React from "react";
import { IMemberOptInSettingsWithAgreement } from "../../reducers/MemberOptInReducer";
import { MemberOptInTypes } from "../../shared/enumerations/MemberOptInSettingsTypes";
import { translate } from "../../translate/translate";
import HistoricPreferenceDisplay from "../../containers/member-start-page/historic-preference-display/HistoricPreferenceDisplay";
import {
    getHistoricDisplayDetails,
    getUnifiedDisplayDetails,
    IMemberOptInDisplayDetails,
    isOptedIn
} from "../../services/MemberOptInService";
import UnifiedPreferenceDisplay from "../../containers/member-start-page/unified-preference-display/UnifiedPreferenceDisplay";
import { PreferenceUpdateCallToAction } from "../../containers/member-start-page/PreferenceUpdateCallToAction";

// TODO Alex, you may be able to make this view "real", or replace/delete it.
export class ProxyMemberView extends React.Component<{
    memberData: IMemberOptInSettingsWithAgreement;
}> {
    constructor(props: { memberData: IMemberOptInSettingsWithAgreement }) {
        super(props);
    }

    render() {
        const memberData = this.props.memberData.memberOptinSettings;
        if (memberData != null) {
            const hasUnifiedPreference = isOptedIn(
                memberData,
                MemberOptInTypes.universalOptIn
            );
            const unifiedDisplayDetails = getUnifiedDisplayDetails(memberData);
            const historicDisplayDetails =
                getHistoricDisplayDetails(memberData);

            return (
                <div className="p-3 my-2 page-view container">
                    <h2>{translate.memberStartPage.pageTitle}</h2>
                    <p>{translate.memberStartPage.description}</p>
                    <HistoricPreferenceDisplay
                        displayDetails={
                            Object.values(
                                historicDisplayDetails
                            ) as IMemberOptInDisplayDetails[]
                        }
                        showHistoricDetails={
                            memberData?.universalOptIn === null
                        }
                        data-test="history-preference-display"
                    />
                    <UnifiedPreferenceDisplay
                        unifiedDisplayDetails={unifiedDisplayDetails}
                        hasUnifiedPreference={hasUnifiedPreference}
                        data-test="unified-preference-display"
                    />
                    <PreferenceUpdateCallToAction
                        hasUnifiedPreference={hasUnifiedPreference}
                        undeliverable={{
                            unified: unifiedDisplayDetails.undeliverable,
                            eNotifications:
                                historicDisplayDetails.eNotifications
                                    ?.undeliverable,
                            eStatements:
                                historicDisplayDetails.eStatements
                                    ?.undeliverable
                        }}
                    />
                </div>
            );
        }

        return null;
    }
}
