import {
    IMemberOptInDetail,
    IMemberPreference
} from "../../shared/models/Members";
import {
    clearOptInRequestState,
    getMemberDetails,
    updateMemberEmail,
    updateMemberOptIn
} from "../../actions/AssociateActions";

import {
    createPersonName,
    createPersonWithOrganizationName,
    createAccountNumberString
} from "../../components/associate/search/MemberResultsTable";

import AssociateUpdateForm from "../../components/associate/associate-form/AssociateUpdateForm";
import AsyncState from "../../shared/enumerations/AsyncState";
import ConfirmationNotification from "../member-start-page/confirmation-notification/ConfirmationNotification";

import Container from "../../components/layout/Container";
import Footer from "./../../components/footer/Footer";
import { IAppState } from "../../store/Store";
import IAsyncStateContainer from "../../shared/types/IAsyncStateContainer";
import {
    IAgreement,
    IMemberOptInSettingsWithAgreement
} from "../../reducers/MemberOptInReducer";
import MemberPreferenceHistory from "../../components/associate/MemberPreferenceHistory";
import { MemberSettingsDetail } from "../../components/associate/MemberSettingsDetail";
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import { OptInDeliveryMethods } from "../../shared/enumerations/OptInDeliveryMethods";
import React from "react";
import { RouteComponentProps } from "react-router";
import TabView from "./../../components/tab-view/TabView";
import WarningInfoPage from "../../components/warning-info-page/WarningInfoPage";
import WarningNotification from "./../../components/warning-notification/WarningNotification";
import { connect } from "react-redux";
import { translate } from "../../translate/translate";

interface IPropsFromState {
    memberDetails: IAsyncStateContainer<IMemberOptInDetail>;
    updateSubmitStatus: AsyncState;
}

interface IPropsFromDispatch {
    getMemberDetails: (userId: string) => any;
    updateMemberOptIn: (
        userId: number,
        newDeliveryMethod: OptInDeliveryMethods
    ) => any;
    updateMemberEmail: (
        userId: number,
        currentUnifiedDeliveryMethod: string,
        newEmail: string
    ) => any;
    clearOptInRequestState: () => any;
}

const mapStateToProps = (appState: IAppState): IPropsFromState => {
    return {
        memberDetails: appState.associateState.memberDetail,
        updateSubmitStatus: appState.associateState.optInSubmitStatus
    };
};

function createDisplayName(agreement: IAgreement) {
    return agreement.byOrgNbr == 0
        ? createPersonName(
              agreement.firstName,
              agreement.middleInitial,
              agreement.lastName
          )
        : createPersonWithOrganizationName(
              agreement.firstName,
              agreement.middleInitial,
              agreement.lastName,
              agreement.organizationName
          );
}

const mapDispatchToProps: IPropsFromDispatch = {
    getMemberDetails: getMemberDetails,
    updateMemberOptIn: updateMemberOptIn,
    updateMemberEmail: updateMemberEmail,
    clearOptInRequestState: clearOptInRequestState
};

interface MatchParams {
    userId: string;
}

type AssociateMemberDetailPageProps = RouteComponentProps<MatchParams> &
    IPropsFromDispatch &
    IPropsFromState &
    RouteComponentProps;

class AssociateMemberDetailPage extends React.Component<
    AssociateMemberDetailPageProps
> {
    constructor(props: AssociateMemberDetailPageProps) {
        super(props);
    }

    async componentDidMount() {
        const {
            match: { params }
        } = this.props;
        if (params?.userId) {
            const userId = params.userId;
            this.props.clearOptInRequestState();
            await this.props.getMemberDetails(userId);
        }
    }

    async componentDidUpdate(prevProps: AssociateMemberDetailPageProps) {
        if (
            prevProps.updateSubmitStatus != AsyncState.Success &&
            this.props.updateSubmitStatus == AsyncState.Success
        ) {
            const {
                match: { params }
            } = this.props;
            await this.props.getMemberDetails(params.userId);
        }
    }

    render() {
        const {
            match: { params }
        } = this.props;
        const memberData = {
            state: this.props.memberDetails.state,
            data: this.props.memberDetails.data?.settings
        } as IAsyncStateContainer<IMemberOptInSettingsWithAgreement>;
        const memberHistory = {
            state: this.props.memberDetails.state,
            data: this.props.memberDetails.data?.history
        } as IAsyncStateContainer<IMemberPreference[]>;

        if (!params?.userId) {
            return (
                <WarningInfoPage
                    title={translate.associate.containers.detail.warning.title}
                    body={translate.associate.containers.detail.warning.body}
                    data-test="associate-member-detail-error"
                />
            );
        }
        return (
            <div>
                <NavigationBar />
                <Container data-test="associate-member-detail-container">
                    <WarningNotification
                        shouldDisplay={
                            this.props.memberDetails.data?.settings
                                .hasPendingUpdates
                        }
                        message={
                            translate.associate.containers.detail.warning
                                .hasPendingUpdates
                        }
                    />
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <h3>{translate.associate.containers.detail.title}</h3>
                        <button
                            className="btn btn-primary mr-5"
                            onClick={() => {
                                this.props.history.goBack();
                            }}
                        >
                            {translate.associate.containers.detail.backButton}
                        </button>
                    </div>
                    <div className="ml-3">
                        <h5 className="auto-table-row">
                            <u>
                                {
                                    translate.associate.containers.detail
                                        .personLabel
                                }
                            </u>
                            {
                                translate.associate.containers.detail
                                    .colonAndSpace
                            }
                            {memberData.data?.agreement
                                ? createDisplayName(memberData.data.agreement)
                                : ""}
                        </h5>
                        <h5>
                            <u>
                                {
                                    translate.associate.containers.detail
                                        .accountNumbersLabel
                                }
                            </u>
                            {
                                translate.associate.containers.detail
                                    .colonAndSpace
                            }
                            {memberData.data?.accounts
                                ? createAccountNumberString(
                                      memberData.data.agreement.agreementNumber,
                                      memberData.data.accounts
                                  )
                                : ""}
                        </h5>
                    </div>
                    <MemberSettingsDetail memberData={memberData} />
                    <ConfirmationNotification
                        displayText={
                            translate.associate.containers.detail
                                .successfulSubmitNotification
                        }
                        submitStatus={this.props.updateSubmitStatus}
                    />
                    <TabView
                        pages={[
                            {
                                header:
                                    translate.associate.containers.detail
                                        .historyTitle,
                                element: (
                                    <MemberPreferenceHistory
                                        history={memberHistory}
                                    />
                                )
                            },
                            {
                                header: "Update Preferences",
                                element: (
                                    <AssociateUpdateForm
                                        memberSettings={memberData}
                                        submitOptInChange={
                                            this.props.updateMemberOptIn
                                        }
                                        submitEmailUpdate={
                                            this.props.updateMemberEmail
                                        }
                                        submitState={
                                            this.props.updateSubmitStatus
                                        }
                                    />
                                )
                            }
                        ]}
                    />
                </Container>
                <Footer />
            </div>
        );
    }
}

export const _AssociateMemberDetailPage = AssociateMemberDetailPage;
// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<IPropsFromState, IPropsFromDispatch, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
)(AssociateMemberDetailPage);
