import React from "react";
import { translate } from "./../../translate/translate";
import logo from "../../assets/LMCU-Blue-Logo-Only.png";

const TroubleLoggingInPage: React.FC = () => {
    return (
        <div className="status-page container">
            <div className="d-flex flex-row justify-content-center">
                <img src={logo} className="center" />
            </div>
            <h1 className="pt-2">
                {translate.errorPages.troubleLoggingIn.title}
            </h1>
            <p>{translate.errorPages.troubleLoggingIn.subText}</p>
        </div>
    );
};

export default TroubleLoggingInPage;
