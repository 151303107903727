import React from "react";
import { IMemberOptInSettingsWithAgreement } from "../../reducers/MemberOptInReducer";
import { MemberTableInformation } from "./MemberTableInformation";

export class MemberSettingsTable extends React.Component<{
    memberData: IMemberOptInSettingsWithAgreement;
}> {
    constructor(props: { memberData: IMemberOptInSettingsWithAgreement }) {
        super(props);
    }

    render() {
        return (
            <table
                className="table table-striped"
                data-test="member-settings-table"
            >
                <thead>
                    <tr>
                        <th>Channel</th>
                        <th>Preference</th>
                        <th>Email</th>
                        <th>Email Notification Status</th>
                    </tr>
                </thead>
                <MemberTableInformation
                    data-test="member-table-information"
                    memberData={this.props.memberData}
                />
            </table>
        );
    }
}
