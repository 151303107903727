import React from "react";
import { translate } from "../../../translate/translate";

export interface IOpenWizardbuttonsProps {
    openWizard: () => void;
    openTerms: () => void;
}

const OpenWizardbuttons: React.FC<IOpenWizardbuttonsProps> = (
    props: IOpenWizardbuttonsProps
) => {
    return (
        <div
            data-test="open-wizard-buttons"
            className="d-flex justify-content-center mt-3"
        >
            <button
                onClick={() => {
                    props.openWizard();
                }}
                className="btn btn-primary"
            >
                {translate.memberStartPage.optInButtonText}
            </button>
            <button
                onClick={() => {
                    props.openTerms();
                }}
                className="btn btn-sm btn-link"
            >
                {translate.memberStartPage.termsOfServiceButtonText}
            </button>
        </div>
    );
};

export default OpenWizardbuttons;
