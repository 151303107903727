import devEnvironment from "../../environments/environment.dev";
import prodEnvironment from "../../environments/environment.prod";
import uatEnvironment from "../../environments/environment.uat";

const getConfigurationFromWindowLocation = () => {
    const hostname = window.location.hostname;
    if (hostname.match("localhost") || hostname.match("qa")) {
        return devEnvironment;
    }
    if (hostname.match("uat")) {
        return uatEnvironment;
    }
    return prodEnvironment;
};

const config = getConfigurationFromWindowLocation();

/**
 * Static class for access application specific configuration data.
 */
export default class Configuration {

    static get Get() {
        return config;
    }

    static PrefixVendorApiUrl(relativeUrl: string) {
        return config.baseUrls.vendorApi + relativeUrl;
    }
}
