import AssociateUpdateFormInput from "./AssociateUpdateFormInput";
import AsyncState from "../../../shared/enumerations/AsyncState";
import IAsyncStateContainer from "../../../shared/types/IAsyncStateContainer";
import { IMemberOptInSettingsWithAgreement } from "../../../reducers/MemberOptInReducer";
import MenuButton from "../../menu-button/MenuButton";
import { OptInDeliveryMethods } from "../../../shared/enumerations/OptInDeliveryMethods";
import React from "react";
import { translate } from "../../../translate/translate";
import { tryUpdateStateWithInput } from "../../../services/InputService";

export interface IAssociateUpdateFormProps {
    memberSettings: IAsyncStateContainer<IMemberOptInSettingsWithAgreement>;
    submitState: AsyncState;
    submitEmailUpdate: (
        userId: number,
        currentUnifiedPreference: string,
        newEmail: string
    ) => any;
    submitOptInChange: (
        userId: number,
        newDeliveryMethod: OptInDeliveryMethods
    ) => any;
}

export interface IAssociateUpdateFormState {
    selectedOption?: OptInDeliveryMethods;
    newEmail: string;
    confirmationEmail: string;
}

type Props = IAssociateUpdateFormProps;

// eslint-disable-next-line @typescript-eslint/ban-types
class AssociateUpdateForm extends React.Component<
    IAssociateUpdateFormProps,
    IAssociateUpdateFormState
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedOption: undefined,
            newEmail: "",
            confirmationEmail: ""
        };
    }

    loadingMessageDisplay = (): any => {
        if (this.props.submitState === AsyncState.Loading) {
            return (
                <div
                    className="container text-center mt-1"
                    data-test="loading-message"
                >
                    <p className="mb-0 fa fa-spinner fa-spin" />{" "}
                    {
                        translate.associate.components.associateUpdateForm
                            .loadingMessage
                    }
                </div>
            );
        }
    };

    errorMessageDisplay = (): any => {
        if (this.props.submitState == AsyncState.Failure) {
            return (
                <div
                    className="container text-center mt-1"
                    data-test="error-message"
                >
                    <p className="text-danger">
                        {
                            translate.associate.components.associateUpdateForm
                                .errorMessage
                        }
                    </p>
                </div>
            );
        }
    };

    clearState() {
        this.setState({
            selectedOption: undefined,
            newEmail: "",
            confirmationEmail: ""
        });
    }

    async submitOptInUpdate() {
        if (
            this.props.memberSettings.data &&
            this.props.memberSettings.data.memberOptinSettings?.userId &&
            this.state.selectedOption
        ) {
            await this.props.submitOptInChange(
                this.props.memberSettings.data.memberOptinSettings?.userId,
                this.state.selectedOption
            );
            if (this.props.submitState == AsyncState.Success) {
                this.clearState();
            }
        }
    }

    async submitEmailUpdate() {
        if (
            this.props.memberSettings.data &&
            this.props.memberSettings.data.memberOptinSettings?.userId &&
            this.props.memberSettings.data.memberOptinSettings
                ?.universalOptIn &&
            this.state.selectedOption &&
            this.state.newEmail === this.state.confirmationEmail
        ) {
            await this.props.submitEmailUpdate(
                this.props.memberSettings.data.memberOptinSettings?.userId,
                this.props.memberSettings.data.memberOptinSettings
                    ?.universalOptIn,
                this.state.confirmationEmail
            );
            if (this.props.submitState == AsyncState.Success) {
                this.clearState();
            }
        }
    }

    render() {
        if (
            !this.props.memberSettings.data ||
            this.props.memberSettings.state !== AsyncState.Success ||
            !this.props.memberSettings.data.memberOptinSettings?.userId
        ) {
            return <div />;
        }
        return (
            <div className="p-5">
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <div className="d-flex flex-wrap justify-content-around align-items-center mb-3">
                        <MenuButton
                            icon={
                                translate.associate.components
                                    .associateUpdateForm.updateEmailButton.icon
                            }
                            text={
                                translate.associate.components
                                    .associateUpdateForm.updateEmailButton.text
                            }
                            markSelected={() => {
                                this.setState({
                                    selectedOption:
                                        OptInDeliveryMethods.Electronic
                                });
                            }}
                            selected={
                                this.state.selectedOption ===
                                OptInDeliveryMethods.Electronic
                            }
                            disabled={
                                this.props.memberSettings.data
                                    .memberOptinSettings?.universalOptIn !==
                                OptInDeliveryMethods.Electronic
                            }
                            data-test="update-email-button"
                        />
                        <MenuButton
                            icon={
                                translate.preferences
                                    .deliveryMethodFontAwesomeIcon.paper
                            }
                            text={
                                translate.associate.components
                                    .associateUpdateForm.paperButton
                            }
                            markSelected={() => {
                                this.setState({
                                    selectedOption: OptInDeliveryMethods.Paper
                                });
                            }}
                            selected={
                                this.state.selectedOption ===
                                OptInDeliveryMethods.Paper
                            }
                            disabled={
                                this.props.memberSettings.data
                                    .memberOptinSettings?.universalOptIn ===
                                OptInDeliveryMethods.Paper
                            }
                            data-test="paper-button"
                        />
                    </div>
                </form>
                <AssociateUpdateFormInput
                    selectedMethod={this.state.selectedOption}
                    email={this.state.newEmail}
                    confirmationEmail={this.state.confirmationEmail}
                    handleConfirmationEmailChange={(newValue) =>
                        this.setState(
                            tryUpdateStateWithInput(
                                this.state,
                                "confirmationEmail",
                                newValue
                            )
                        )
                    }
                    handleEmailChange={(newValue) =>
                        this.setState(
                            tryUpdateStateWithInput(
                                this.state,
                                "newEmail",
                                newValue
                            )
                        )
                    }
                    submitEmailChange={async () => {
                        await this.submitEmailUpdate();
                    }}
                    submitOptInChange={async () => {
                        await this.submitOptInUpdate();
                    }}
                    clearSelection={() => {
                        this.clearState();
                    }}
                />
                {this.loadingMessageDisplay()}
                {this.errorMessageDisplay()}
            </div>
        );
    }
}

export default AssociateUpdateForm;
