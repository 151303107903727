import React from "react";
import WarningInfoPage from "../../components/warning-info-page/WarningInfoPage";

const AccessDeniedPage: React.FC = () => {
    return (
        <WarningInfoPage
            title="Access Denied"
            body="Please contact IT Operations if this is an error."
        />
    );
};

export default AccessDeniedPage;