import AsyncState from "../shared/enumerations/AsyncState";
import Axios from "axios";
import Configuration from "../shared/configuration/Configuration";
import IAsyncStateContainer from "../shared/types/IAsyncStateContainer";
import {
    EmailStatus,
    IMemberOptInInformation,
    IMemberOptInSettings
} from "../reducers/MemberOptInReducer";
import { MemberOptInTypes } from "../shared/enumerations/MemberOptInSettingsTypes";
import { OptInDeliveryMethods } from "../shared/enumerations/OptInDeliveryMethods";
import moment from "moment";
import { translate } from "../translate/translate";

export interface IMemberOptInDisplayDetails {
    displayName: string;
    deliveryMethod: string;
    deliveryMethodDisplayText: string;
    faIcon: string;
    email?: string;
    undeliverable?: boolean;
}
export const getMemberOptInPreference = async (): Promise<
    IAsyncStateContainer<IMemberOptInInformation>
> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.getoptin;
        const res = await Axios.get(url, { withCredentials: true });

        return {
            state: AsyncState.Success,
            data: res.data
        };
    } catch (err) {
        if (err.response?.status == 404) {
            return {
                state: AsyncState.Success,
                data: undefined
            };
        }

        return {
            state: AsyncState.Failure
        };
    }
};

export const submitMemberOptIn = async (
    memberEmail: string
): Promise<AsyncState> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.submitoptin;

        const body = {
            Email: memberEmail,
            Value: "Electronic",
            LegalReference: moment().format("YYYY-MM-DDTHH:mm:ss")
        };

        const res = await Axios.post(url, body, { withCredentials: true });

        if (res.status == 200) {
            return AsyncState.Success;
        }

        return AsyncState.Failure;
    } catch (err) {
        return AsyncState.Failure;
    }
};

export const submitMemberOptOut = async (): Promise<AsyncState> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.submitoptin;

        const body = {
            Value: "Paper",
            LegalReference: moment().format("YYYY-MM-DDTHH:mm:ss")
        };

        const res = await Axios.post(url, body, { withCredentials: true });

        if (res.status == 200) {
            return AsyncState.Success;
        }

        return AsyncState.Failure;
    } catch (err) {
        return AsyncState.Failure;
    }
};

export const isOptedIn = (
    optInSettings: IMemberOptInSettings,
    setting: MemberOptInTypes
): boolean => {
    switch (setting) {
        case MemberOptInTypes.eStatements:
            return !!optInSettings["eStatements"];
        case MemberOptInTypes.eNotifications:
            return !!optInSettings["eNotifications"];
        case MemberOptInTypes.universalOptIn:
            return !!optInSettings["universalOptIn"];
    }
};

export const getEStatementsDisplayDetails = (
    optInSettings: IMemberOptInSettings
): IMemberOptInDisplayDetails => {
    switch (optInSettings.eStatements) {
        case OptInDeliveryMethods.Paper:
            return {
                displayName: translate.preferences.displayNames.eStatements,
                deliveryMethod: translate.preferences.deliveryMethodText.paper,
                deliveryMethodDisplayText:
                    translate.preferences.deliveryMethodFriendlyText
                        .estatementsPaper,
                faIcon: translate.preferences.deliveryMethodFontAwesomeIcon
                    .paper
            };
        case OptInDeliveryMethods.Electronic:
            return {
                displayName: translate.preferences.displayNames.eStatements,
                deliveryMethod:
                    translate.preferences.deliveryMethodText.electronic,
                deliveryMethodDisplayText:
                    translate.preferences.deliveryMethodFriendlyText
                        .electronic +
                    (optInSettings.eStatementsEmail
                        ? " " + optInSettings.eStatementsEmail
                        : ""),
                faIcon: translate.preferences.deliveryMethodFontAwesomeIcon
                    .electronic,
                email: optInSettings.eStatementsEmail,
                undeliverable:
                    optInSettings.eStatementsEmailStatus ===
                    EmailStatus.FailingDelivery
            };

        default:
            return {
                displayName: translate.preferences.displayNames.eStatements,
                deliveryMethod:
                    translate.preferences.deliveryMethodText.default,
                deliveryMethodDisplayText:
                    translate.preferences.deliveryMethodFriendlyText.default,
                faIcon: translate.preferences.deliveryMethodFontAwesomeIcon
                    .default
            };
    }
};

export const getENotificationDisplayDetails = (
    optInSettings: IMemberOptInSettings
): IMemberOptInDisplayDetails => {
    switch (optInSettings.eNotifications) {
        case OptInDeliveryMethods.Paper:
            return {
                displayName: translate.preferences.displayNames.eNotifications,
                deliveryMethod: translate.preferences.deliveryMethodText.paper,
                deliveryMethodDisplayText:
                    translate.preferences.deliveryMethodFriendlyText
                        .eNotificationsPaper,
                faIcon: translate.preferences.deliveryMethodFontAwesomeIcon
                    .paper
            };
        case OptInDeliveryMethods.Electronic:
            return {
                displayName: translate.preferences.displayNames.eNotifications,
                deliveryMethod:
                    translate.preferences.deliveryMethodText.electronic,
                deliveryMethodDisplayText:
                    translate.preferences.deliveryMethodFriendlyText
                        .electronic +
                    (optInSettings.eNotificationsEmail
                        ? " " + optInSettings.eNotificationsEmail
                        : ""),
                faIcon: translate.preferences.deliveryMethodFontAwesomeIcon
                    .electronic,
                email: optInSettings.eNotificationsEmail,
                undeliverable:
                    optInSettings.eNotificationsEmailStatus ===
                    EmailStatus.FailingDelivery
            };

        default:
            return {
                displayName: translate.preferences.displayNames.eNotifications,
                deliveryMethod:
                    translate.preferences.deliveryMethodText.default,
                deliveryMethodDisplayText:
                    translate.preferences.deliveryMethodFriendlyText.default,
                faIcon: translate.preferences.deliveryMethodFontAwesomeIcon
                    .default
            };
    }
};

export const getUnifiedDisplayDetails = (
    optInSettings: IMemberOptInSettings
): IMemberOptInDisplayDetails => {
    switch (optInSettings.universalOptIn) {
        case OptInDeliveryMethods.Electronic:
            return {
                displayName: translate.preferences.displayNames.unifiedOptIn,
                deliveryMethod:
                    translate.preferences.deliveryMethodText.electronic,
                deliveryMethodDisplayText:
                    translate.preferences.unifiedFriendlyText.electronic,
                faIcon: "",
                email: optInSettings.unifiedOptInEmail,
                undeliverable:
                    optInSettings.unifiedOptInEmailStatus ===
                    EmailStatus.FailingDelivery
            };
        case OptInDeliveryMethods.Paper:
            return {
                displayName: translate.preferences.displayNames.unifiedOptIn,
                deliveryMethod: translate.preferences.deliveryMethodText.paper,
                deliveryMethodDisplayText:
                    translate.preferences.unifiedFriendlyText.paper,
                faIcon: ""
            };
        default:
            return {
                displayName: translate.preferences.displayNames.unifiedOptIn,
                deliveryMethod:
                    translate.preferences.deliveryMethodText.default,
                deliveryMethodDisplayText:
                    translate.preferences.unifiedFriendlyText.default,
                faIcon: ""
            };
    }
};

type IHistoricDisplayDetails = Partial<
    Record<"eStatements" | "eNotifications", IMemberOptInDisplayDetails>
>;
export const getHistoricDisplayDetails = (
    optInSettings: IMemberOptInSettings
): IHistoricDisplayDetails => {
    const displayDetails: IHistoricDisplayDetails = {};

    if (isOptedIn(optInSettings, MemberOptInTypes.eStatements)) {
        displayDetails.eStatements =
            getEStatementsDisplayDetails(optInSettings);
    }

    if (isOptedIn(optInSettings, MemberOptInTypes.eNotifications)) {
        displayDetails.eNotifications =
            getENotificationDisplayDetails(optInSettings);
    }

    return displayDetails;
};
