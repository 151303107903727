import {
    MemberOptInFormActionTypes,
    MemberOptInFormActions
} from "../actions/MemberOptInFormActions";
import AsyncState from "../shared/enumerations/AsyncState";
import { Reducer } from "redux";

export interface IMemberOptInFormData {
    readonly memberAcceptedTerms: boolean;
    readonly unifiedOptinEmail: string;
    readonly confirmationEmail: string;
    readonly submitStatus: AsyncState;
}

export interface IMemberOptInRequestState {
    memberOptInFormData: IMemberOptInFormData;
}

const initialmemberOptInRequestState: IMemberOptInRequestState = {
    memberOptInFormData: {
        memberAcceptedTerms: false,
        unifiedOptinEmail: "",
        confirmationEmail: "",
        submitStatus: AsyncState.Inactive
    }
};

export const MemberOptInFormReducer: Reducer<
    IMemberOptInRequestState,
    MemberOptInFormActions
> = (state = initialmemberOptInRequestState, action) => {
    switch (action.type) {
        case MemberOptInFormActionTypes.TOGGLE_MEMBER_ACCEPTANCE:
            return {
                ...state,
                memberOptInFormData: {
                    ...state.memberOptInFormData,
                    memberAcceptedTerms: !state.memberOptInFormData
                        .memberAcceptedTerms
                }
            };
        case MemberOptInFormActionTypes.UPDATE_MEMBER_OPTIN_EMAIL:
            return {
                ...state,
                memberOptInFormData: {
                    ...state.memberOptInFormData,
                    unifiedOptinEmail: action.unifiedOptInEmail
                }
            };
        case MemberOptInFormActionTypes.UPDATE_MEMBER_CONFIRMATION_EMAIL:
            return {
                ...state,
                memberOptInFormData: {
                    ...state.memberOptInFormData,
                    confirmationEmail: action.confirmationEmail
                }
            };
        case MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTIN_FORM:
            return {
                ...state,
                memberOptInFormData: {
                    ...state.memberOptInFormData,
                    submitStatus: action.submitResults
                }
            };
        case MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTOUT:
            return {
                ...state,
                memberOptInFormData: {
                    ...state.memberOptInFormData,
                    submitStatus: action.submitResults
                }
            };
        case MemberOptInFormActionTypes.CLEAR_MEMBER_OPTIN_FORM:
            return initialmemberOptInRequestState;
        default:
            return state;
    }
};
