import React from "react";

export interface IWizardButtonControlProps {
    goBack: () => void;
    goNext: () => void;
    disableNext?: boolean;
    disableBack?: boolean;
    customNextText?: string;
}

const WizardButtonControl: React.FC<IWizardButtonControlProps> = (props) => {
    return (
        <div className="d-flex flex-row justify-content-end">
            <button
                style={{ marginRight: "10px" }}
                data-test="wiz-back-button"
                disabled={props.disableBack}
                onClick={() => {
                    props.goBack();
                }}
                className="btn btn-secondary"
            >
                Back
            </button>
            <button
                data-test="wiz-next-button"
                disabled={props.disableNext}
                onClick={() => {
                    props.goNext();
                }}
                className="btn btn-primary"
            >
                {props.customNextText !== undefined
                    ? props.customNextText
                    : "Next"}
            </button>
        </div>
    );
};

export default WizardButtonControl;
