import "./WarningInfoPage.scss";
import React from "react";

interface IWarningInfoPageProps {
    title: string;
    body?: string;
}

const WarningInfoPage: React.FC<IWarningInfoPageProps> = (props) => {
    return (
        <div className="warning-info-page">
            <h1 aria-hidden="true"><i className="fa fa-exclamation-triangle" /></h1>
            <h1>{props.title}</h1>
            <p>{props.body}</p>
        </div>
    );
};

export default WarningInfoPage;