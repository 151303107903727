import { IMemberOptInSettingsWithAgreement } from "../../../reducers/MemberOptInReducer";
import MemberResultsTable from "./MemberResultsTable";
import React from "react";
import IAsyncStateContainer from "../../../shared/types/IAsyncStateContainer";
import AsyncState from "../../../shared/enumerations/AsyncState";
import { translate } from "../../../translate/translate";

class MemberSearchResults extends React.Component<{
    memberSelectHandler: (member: IMemberOptInSettingsWithAgreement) => any;
    searchResults: IAsyncStateContainer<IMemberOptInSettingsWithAgreement[]>;
}> {
    constructor(props: {
        memberSelectHandler: (
            elementClicked: IMemberOptInSettingsWithAgreement
        ) => any;
        searchResults: IAsyncStateContainer<
            IMemberOptInSettingsWithAgreement[]
        >;
    }) {
        super(props);
    }

    render() {
        if (
            this.props.searchResults?.data &&
            this.props.searchResults.state === AsyncState.Success
        ) {
            return (
                <MemberResultsTable
                    queryData={this.props.searchResults.data || []}
                    onRowClick={this.props.memberSelectHandler}
                    data-test="member-results-table"
                />
            );
        }
        if (this.props.searchResults.state === AsyncState.Loading) {
            return (
                <div data-test="member-results-loading">
                    <p className="mb-0 fa fa-spinner fa-spin" />{" "}
                    {translate.associate.components.memberSearchResults.loading}
                </div>
            );
        }
        if (this.props.searchResults.state === AsyncState.Failure) {
            return (
                <div>
                    {translate.associate.components.memberSearchResults.failure}
                </div>
            );
        }
        return (
            <div>
                {translate.associate.components.memberSearchResults.empty}
            </div>
        );
    }
}

export default MemberSearchResults;
