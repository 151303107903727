import React from "react";
import { AutoTable } from "../../auto-table/AutoTable";
import {
    IAgreementAccount,
    IMemberOptInSettingsWithAgreement
} from "../../../reducers/MemberOptInReducer";
import { translate } from "../../../translate/translate";
import { List } from "immutable";

export function createPersonName(
    firstName: string | undefined,
    middleInitial: string | undefined,
    lastName: string | undefined
) {
    if (middleInitial)
        return `${firstName} ${middleInitial} ${lastName}`.trim();
    return `${firstName} ${lastName}`.trim();
}

export function createPersonWithOrganizationName(
    firstName: string | undefined,
    middleInitial: string | undefined,
    lastName: string | undefined,
    orgName: string | undefined
) {
    const personName = createPersonName(firstName, middleInitial, lastName);
    const organizationName = orgName ? orgName.trim() : "";
    return `${personName} - [${organizationName}]`;
}

export function createAccountNumberString(
    agreementNumber: number,
    accounts: IAgreementAccount[] | undefined
) {
    let accountNumbers = List<string>();
    accounts?.forEach((account) => {
        if (
            account?.accountNumber &&
            account?.accountOwner?.agreementNumber == agreementNumber
        )
            accountNumbers = accountNumbers.push(`*${account.accountNumber}`);
        if (
            account?.accountNumber &&
            account?.accountOwner?.agreementNumber != agreementNumber
        )
            accountNumbers = accountNumbers.push(`${account.accountNumber}`);
    });

    return accountNumbers.join(", ");
}

class MemberResultsTable extends React.Component<{
    queryData: IMemberOptInSettingsWithAgreement[];
    onRowClick: (elementClicked: IMemberOptInSettingsWithAgreement) => any;
}> {
    private headers = [
        translate.associate.components.memberTable.headers.person,
        translate.associate.components.memberTable.headers.accountNumbers,
        translate.associate.components.memberTable.headers.eNotices,
        translate.associate.components.memberTable.headers.eStatements,
        translate.associate.components.memberTable.headers.unified
    ];
    private cells = [
        (x: IMemberOptInSettingsWithAgreement) =>
            x.agreement.byOrgNbr == 0
                ? createPersonName(
                      x.agreement.firstName,
                      x.agreement.middleInitial,
                      x.agreement.lastName
                  )
                : createPersonWithOrganizationName(
                      x.agreement.firstName,
                      x.agreement.middleInitial,
                      x.agreement.lastName,
                      x.agreement.organizationName
                  ),
        (x: IMemberOptInSettingsWithAgreement) =>
            createAccountNumberString(x.agreement.agreementNumber, x.accounts),
        (x: IMemberOptInSettingsWithAgreement) =>
            x.memberOptinSettings?.eNotifications ||
            translate.associate.components.memberTable.values.unknown,
        (x: IMemberOptInSettingsWithAgreement) =>
            x.memberOptinSettings?.eStatements ||
            translate.associate.components.memberTable.values.unknown,
        (x: IMemberOptInSettingsWithAgreement) =>
            x.memberOptinSettings?.universalOptIn ||
            translate.associate.components.memberTable.values.unknown
    ];

    constructor(props: {
        queryData: IMemberOptInSettingsWithAgreement[];
        onRowClick: (elementClicked: IMemberOptInSettingsWithAgreement) => any;
    }) {
        super(props);
    }

    render() {
        return (
            <AutoTable
                elements={this.props.queryData}
                headers={this.headers}
                cells={this.cells}
                data-test="member-table-wrapper"
                onRowClick={this.props.onRowClick}
            />
        );
    }
}

export default MemberResultsTable;
