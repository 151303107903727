import React from "react";

interface IWizardPageContainerProps {
    pageTitle?: string;
    pageDescription?: string;
}

const WizardPageContainer: React.FC<IWizardPageContainerProps> = (props) => {
    return (
        <div className="container p-2">
            <h3>{props.pageTitle}</h3>
            <p>{props.pageDescription}</p>
            <hr></hr>
            {props.children}
        </div>
    );
};

export default WizardPageContainer;
