import {UserInfo} from "react-adal";

export enum SecurityActionTypes {
    APPLY_USER = "APPLY_USER",
    APPLY_TOKEN = "APPLY_TOKEN"
}

export interface ISecurityApplyAction {
    type: SecurityActionTypes.APPLY_USER;
    user: UserInfo;
}

export interface ISecurityTokenAction {
    type: SecurityActionTypes.APPLY_TOKEN;
    userToken: string;
}

export type SecurityActions = ISecurityApplyAction | ISecurityTokenAction;

