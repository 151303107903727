/* eslint-disable sort-imports */

/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import {
    connectRouter,
    routerMiddleware,
    RouterState
} from "connected-react-router";
import { createBrowserHistory, History, LocationState } from "history";

import throttle from "lodash.throttle";
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
/*
  Dev-Tools for debugging and visualizing Redux State in browser
*/
import { composeWithDevTools } from "redux-devtools-extension";
/*  Thunk
  Redux Thunk middleware allows you to write action creators that return a function instead of an action.
  The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain condition is met.
  The inner function receives the store methods dispatch and getState as parameters.
*/
import thunk from "redux-thunk";

// Import reducers and state type
import { ISecurityState, securityReducer } from "../reducers/SecurityReducer";
import {
    IMemberOptInState,
    memberOptInReducer
} from "../reducers/MemberOptInReducer";
import LocalStorageService from "../services/LocalStorageService";
import Configuration from "../shared/configuration/Configuration";
import {
    IMemberOptInRequestState,
    MemberOptInFormReducer
} from "../reducers/MemberOptInFormReducer";
import {
    associateSearchReducer,
    IAssociateSearchResultState
} from "../reducers/AssociateReducer";

// Create an interface for the application state
export interface IAppState {
    router: RouterState & LocationState;
    securityState: ISecurityState;
    memberOptInState: IMemberOptInState;
    memberOptInRequestState: IMemberOptInRequestState;
    associateState: IAssociateSearchResultState;
}

export const AppRouteHistory = createBrowserHistory();

// Create the root reducer
export const rootReducer = (history: History<any>) =>
    combineReducers<IAppState>({
        router: connectRouter(history),
        securityState: securityReducer,
        memberOptInState: memberOptInReducer,
        memberOptInRequestState: MemberOptInFormReducer,
        associateState: associateSearchReducer
    });

// Create a configure store function of type `IAppState`
export default function configureStore(initState: any): Store<IAppState, any> {
    const enhancer = composeWithDevTools(
        applyMiddleware(routerMiddleware(AppRouteHistory), thunk)
    );

    const store = createStore(
        rootReducer(AppRouteHistory),
        initState,
        enhancer
    );

    store.subscribe(
        throttle(() => {
            LocalStorageService.saveAppState(
                store.getState(),
                Configuration.Get.statesToSave
            );
        }, 2000)
    );

    return store;
}
