//import "bootstrap/dist/css/bootstrap.css";
import "./theme/theme.scss";
import "font-awesome/css/font-awesome.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { adalConfig, authContext, getToken, getUser } from "./adalConfig";
import configureStore, { IAppState } from "./store/Store";

import App from "./App";
import Axios from "axios";
import Configuration from "./shared/configuration/Configuration";
import LocalStorageService from "./services/LocalStorageService";
import { Provider } from "react-redux";
import { SecurityActionTypes } from "./actions/SecurityActions";
import { Store } from "redux";
import { ToastContainer } from "react-toastify";
import { runWithAdal } from "react-adal";

export interface IProps {
    store: Store<IAppState>;
}

const DO_NOT_LOGIN = true;

const dispatchSecuritySetup = (store: any, authContext: any) => {
    store.dispatch({
        type: SecurityActionTypes.APPLY_USER,
        user: getUser()
    });
    store.dispatch({
        type: SecurityActionTypes.APPLY_TOKEN,
        user: getToken()
    });
    Axios.defaults.headers.common["Authorization"] = "Bearer " + getToken();

    Axios.interceptors.response.use(undefined, (err) => {
        if (
            err.response &&
            err.response.status === 401 &&
            err.response.config &&
            !err.response.config.__isRetryRequest
        ) {
            err.response.config.__isRetryRequest = true;
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                authContext.acquireToken(adalConfig.clientId, () => {
                    Axios.defaults.headers.common["Authorization"] =
                        "Bearer " + getToken();
                    err.config.headers.Authorization = "Bearer " + getToken();

                    Axios(err.config).then(resolve, reject);
                });
            });
        }
        throw err;
    });
};

const startApplication = (store: any) => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
            <div className="toast-container-wrapper">
                <ToastContainer />
            </div>
        </Provider>,
        document.getElementById("lmcuOptinRoot")
    );
};

const store = configureStore(
    LocalStorageService.loadAppSavedState(Configuration.Get.statesToSave)
);

if (window === window.parent) {
    runWithAdal(
        authContext,
        () => {
            dispatchSecuritySetup(store, authContext);
            startApplication(store);
        },
        DO_NOT_LOGIN
    );
} else {
    startApplication(store);
}
