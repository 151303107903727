import { NotificationBarType } from "../../components/notification-bar/NotificationBar";

enum AsyncState {
    Inactive = "Inactive",
    Loading = "Loading",
    Success = "Success",
    Failure = "Error",
}

export const mapAsyncStateToNotificationType = (asyncState: AsyncState): NotificationBarType => {
    switch (asyncState) {
        case AsyncState.Inactive:
            return NotificationBarType.Info;
        case AsyncState.Loading:
            return NotificationBarType.Loading;
        case AsyncState.Success:
            return NotificationBarType.Success;
        case AsyncState.Failure:
            return NotificationBarType.Danger;
    }
};

export default AsyncState;