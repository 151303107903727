import { ActionCreator, Dispatch } from "redux";
import {
    getMemberDetail,
    queryMemberList,
    submitMemberOptInUpdate
} from "../services/AssociateService";

import AsyncState from "../shared/enumerations/AsyncState";
import { IAssociateSearchResultState } from "../reducers/AssociateReducer";
import IAsyncStateContainer from "../shared/types/IAsyncStateContainer";
import { IMemberOptInDetail } from "../shared/models/Members";
import { IMemberOptInSettingsWithAgreement } from "../reducers/MemberOptInReducer";
import { OptInDeliveryMethods } from "../shared/enumerations/OptInDeliveryMethods";
import { ThunkAction } from "redux-thunk";
import { submitMemberEmailUpdate } from "../services/AssociateService";

export enum AssociateActionTypes {
    GET_MEMBER_LIST = "AAT_GET_MEMBER_LIST",
    GET_MEMBER_DETAIL = "AAT_GET_MEMBER_DETAIL",
    SUBMIT_OPTIN_UPDATE = "AAT_SUBMIT_OPTIN_UPDATES",
    SUBMIT_EMAIL_UPDATE = "AAT_SUBMIT_EMAIL_UPDATE",
    CLEAR_UPDATE_REQUEST_STATE = "AAT_CLEAR_UPDATE_REQUEST_STATE"
}

export interface IAssociateGetMemberList {
    type: AssociateActionTypes.GET_MEMBER_LIST;
    associateSearchResults: IAsyncStateContainer<
        IMemberOptInSettingsWithAgreement[]
    >;
}

export interface IAssociateGetMemberDetail {
    type: AssociateActionTypes.GET_MEMBER_DETAIL;
    memberDetail: IAsyncStateContainer<IMemberOptInDetail>;
}

export interface IAssociateSubmitEmailUpdateAction {
    type: AssociateActionTypes.SUBMIT_EMAIL_UPDATE;
    submitState: AsyncState;
}

export interface IAssociateSubmitOptinUpdateAction {
    type: AssociateActionTypes.SUBMIT_OPTIN_UPDATE;
    submitState: AsyncState;
}

export interface IAssociateClearOptinRequestState {
    type: AssociateActionTypes.CLEAR_UPDATE_REQUEST_STATE;
}

export type AssociateActions =
    | IAssociateGetMemberList
    | IAssociateGetMemberDetail
    | IAssociateSubmitOptinUpdateAction
    | IAssociateSubmitEmailUpdateAction
    | IAssociateClearOptinRequestState;

export const clearOptInRequestState: ActionCreator<IAssociateClearOptinRequestState> = () => {
    return {
        type: AssociateActionTypes.CLEAR_UPDATE_REQUEST_STATE
    };
};

export const queryOptInMembers: ActionCreator<ThunkAction<
    Promise<any>,
    IAssociateSearchResultState,
    null,
    IAssociateGetMemberList
>> = (query: string) => {
    return async (dispatch: Dispatch<IAssociateGetMemberList>) => {
        dispatch({
            type: AssociateActionTypes.GET_MEMBER_LIST,
            associateSearchResults: { state: AsyncState.Loading }
        });

        const searchResults = await queryMemberList(query);

        dispatch({
            type: AssociateActionTypes.GET_MEMBER_LIST,
            associateSearchResults: searchResults
        });
    };
};

export const getMemberDetails: ActionCreator<ThunkAction<
    Promise<any>,
    IAssociateSearchResultState,
    null,
    IAssociateGetMemberDetail
>> = (userId: string) => {
    return async (dispatch: Dispatch<IAssociateGetMemberDetail>) => {
        dispatch({
            type: AssociateActionTypes.GET_MEMBER_DETAIL,
            memberDetail: { state: AsyncState.Loading }
        });

        const memberDetail = await getMemberDetail(userId);

        dispatch({
            type: AssociateActionTypes.GET_MEMBER_DETAIL,
            memberDetail: memberDetail
        });
    };
};

export const updateMemberEmail: ActionCreator<ThunkAction<
    Promise<any>,
    IAssociateSearchResultState,
    null,
    IAssociateSubmitEmailUpdateAction
>> = (
    userId: number,
    currentDeliveryMethod: OptInDeliveryMethods,
    newEmail: string
) => {
    return async (dispatch: Dispatch<IAssociateSubmitEmailUpdateAction>) => {
        dispatch({
            type: AssociateActionTypes.SUBMIT_EMAIL_UPDATE,
            submitState: AsyncState.Loading
        });

        const results = await submitMemberEmailUpdate(
            userId,
            currentDeliveryMethod,
            newEmail
        );

        dispatch({
            type: AssociateActionTypes.SUBMIT_EMAIL_UPDATE,
            submitState: results
        });
    };
};

export const updateMemberOptIn: ActionCreator<ThunkAction<
    Promise<any>,
    IAssociateSearchResultState,
    null,
    IAssociateSubmitOptinUpdateAction
>> = (userId: number, newDeliveryMethod: OptInDeliveryMethods) => {
    return async (dispatch: Dispatch<IAssociateSubmitOptinUpdateAction>) => {
        dispatch({
            type: AssociateActionTypes.SUBMIT_OPTIN_UPDATE,
            submitState: AsyncState.Loading
        });

        const results = await submitMemberOptInUpdate(
            userId,
            newDeliveryMethod
        );

        dispatch({
            type: AssociateActionTypes.SUBMIT_OPTIN_UPDATE,
            submitState: results
        });
    };
};
