import React from "react";
import IAsyncStateContainer from "../../shared/types/IAsyncStateContainer";
import { IMemberOptInSettingsWithAgreement } from "../../reducers/MemberOptInReducer";
import AsyncState from "../../shared/enumerations/AsyncState";
import { translate } from "../../translate/translate";
import { MemberSettingsTable } from "./MemberSettingsTable";
import { ProxyMemberView } from "./ProxyMemberView";

export class MemberSettingsDetail extends React.Component<{
    memberData: IAsyncStateContainer<IMemberOptInSettingsWithAgreement>;
}> {
    constructor(props: {
        memberData: IAsyncStateContainer<IMemberOptInSettingsWithAgreement>;
    }) {
        super(props);
    }

    render() {
        if (
            this.props.memberData?.data &&
            this.props.memberData.state === AsyncState.Success
        ) {
            const memberData = this.props.memberData.data;
            return (
                <div>
                    <h4>What the member sees:</h4>
                    <ProxyMemberView
                        memberData={memberData}
                        data-test="proxy-member-view"
                    />
                    <h4>Current Preferences</h4>
                    <MemberSettingsTable
                        memberData={memberData}
                        data-test="member-settings-table"
                    />
                </div>
            );
        }
        if (this.props.memberData.state === AsyncState.Loading) {
            return (
                <div data-test="member-settings-detail-loading">
                    <p className="mb-0 fa fa-spinner fa-spin" />{" "}
                    {
                        translate.associate.components.memberPreferenceHistory
                            .loading
                    }
                </div>
            );
        }
        if (this.props.memberData.state === AsyncState.Failure) {
            return (
                <div>
                    {
                        translate.associate.components.memberPreferenceHistory
                            .failure
                    }
                </div>
            );
        }
        return (
            <div>
                {translate.associate.components.memberPreferenceHistory.empty}
            </div>
        );
    }
}
