import DisplayBar, {
    DisplayBarColor
} from "../../components/display-bar/DisplayBar";

import React from "react";

interface IWarningNotification {
    shouldDisplay?: boolean;
    message: string;
}

const WarningNotification: React.FC<IWarningNotification> = (props) => {
    if (props.shouldDisplay) {
        return (
            <DisplayBar
                color={DisplayBarColor.Warning}
                icon="fa-exclamation-circle"
            >
                <p className="mb-0">{props.message}</p>
            </DisplayBar>
        );
    }
    return <div />;
};

export default WarningNotification;
