import { IMemberOptInDisplayDetails } from "../../../services/MemberOptInService";
import React from "react";
import { translate } from "../../../translate/translate";

export interface IHistoricPreferenceDisplayProps {
    displayDetails: IMemberOptInDisplayDetails[];
    showHistoricDetails?: boolean;
}

const HistoricPreferenceDisplay: React.FC<IHistoricPreferenceDisplayProps> = (
    props: IHistoricPreferenceDisplayProps
) => {
    if (props.showHistoricDetails === true) {
        return (
            <div data-test="historic-preference">
                <p>
                    <b>{translate.memberStartPage.historicPreferencesLabel}</b>
                </p>
                <div className="px-5">
                    {props.displayDetails.map((details, i) => {
                        return (
                            <p key={i} data-test="preference-description">
                                <b>{details.displayName + ": "}</b>
                                {details.deliveryMethodDisplayText}
                            </p>
                        );
                    })}
                </div>
            </div>
        );
    }

    return <div data-test="no-historic-preferences" />;
};

export default HistoricPreferenceDisplay;
