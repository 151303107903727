import {
    emailFormatValidator,
    emailMatchValidator,
    emailsDoMatch
} from "../../../shared/validators/EmailValidators";

import AsyncState from "../../../shared/enumerations/AsyncState";
import EmailInput from "./EmailInput";
import { IValidationState } from "./../../../shared/validators/IValidationState";
import React from "react";
import WizardButtonControl from "../../../components/wizard-button-control/WizardButtonControl";
import WizardPageContainer from "../wizard-page-container/WizardPageContainer";
import { translate } from "../../../translate/translate";

export interface IEmailPageProps {
    unifiedEmailAddress: string;
    confirmationEmailAddress: string;
    handleEmailInputChange: (newValue: string) => any;
    handleEmailConfirmationChange: (newValue: string) => any;
    goBack: () => any;
    goNext: () => any;
    submitStatus: AsyncState;
    customNextText?: string;
}

type Props = IEmailPageProps;

// eslint-disable-next-line @typescript-eslint/ban-types
class EmailPage extends React.Component<
    IEmailPageProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
> {
    constructor(props: Props) {
        super(props);

        this.validateEmailFormat.bind(this);
        this.validateEmailsMatch.bind(this);
    }

    validateEmailFormat = (): IValidationState => {
        return emailFormatValidator(this.props.unifiedEmailAddress);
    };

    validateEmailsMatch = (): IValidationState => {
        return emailMatchValidator(
            this.props.unifiedEmailAddress,
            this.props.confirmationEmailAddress
        );
    };

    emailsDoMatch = (): boolean => {
        return emailsDoMatch(
            this.props.unifiedEmailAddress,
            this.props.confirmationEmailAddress
        );
    };

    render() {
        return (
            <WizardPageContainer
                pageTitle={translate.optInWizard.emailPage.pageTitle}
                pageDescription={
                    translate.optInWizard.emailPage.pageInformation
                }
            >
                <EmailInput
                    unifiedEmailAddress={this.props.unifiedEmailAddress}
                    confirmationEmailAddress={
                        this.props.confirmationEmailAddress
                    }
                    emailFormatValidation={this.validateEmailFormat}
                    emailMatchValidation={this.validateEmailsMatch}
                    handleEmailConfirmationChange={(newValue: string) => {
                        this.props.handleEmailConfirmationChange(newValue);
                    }}
                    handleEmailInputChange={(newValue: string) => {
                        this.props.handleEmailInputChange(newValue);
                    }}
                    data-test="email-input-component"
                />
                <WizardButtonControl
                    goNext={this.props.goNext}
                    goBack={this.props.goBack}
                    disableNext={!this.emailsDoMatch()}
                    customNextText={this.props.customNextText}
                />
            </WizardPageContainer>
        );
    }
}

export default EmailPage;
