import DisplayBar, {
    DisplayBarColor
} from "../../../components/display-bar/DisplayBar";

import AsyncState from "../../../shared/enumerations/AsyncState";
import EmailPage from "../../../containers/unified-wizard/email-page/EmailPage";
import { OptInDeliveryMethods } from "../../../shared/enumerations/OptInDeliveryMethods";
import React from "react";
import WizardButtonControl from "../../../components/wizard-button-control/WizardButtonControl";
import { translate } from "../../../translate/translate";

interface IAssociateUpdateFormInputProps {
    selectedMethod?: OptInDeliveryMethods;
    email: string;
    confirmationEmail: string;
    handleEmailChange: (newValue: string) => any;
    handleConfirmationEmailChange: (newValue: string) => any;
    submitEmailChange: () => any;
    submitOptInChange: () => any;
    clearSelection: () => any;
}

const AssociateUpdateFormInput: React.FC<IAssociateUpdateFormInputProps> = (
    props
) => {
    const optInInputDisplay = (
        marketingText: string,
        color: DisplayBarColor
    ): any => {
        return (
            <div className="px-5 mx-3">
                <DisplayBar color={color} icon="fa-info-circle">
                    <p className="mb-0">{marketingText}</p>
                </DisplayBar>
                <WizardButtonControl
                    customNextText={
                        translate.associate.components.associateFormInput
                            .submitButtonText
                    }
                    goNext={() => props.submitOptInChange()}
                    goBack={() => props.clearSelection()}
                />
            </div>
        );
    };

    switch (props.selectedMethod) {
        case OptInDeliveryMethods.Paper:
            return optInInputDisplay(
                translate.associate.components.associateFormInput
                    .toPaperMarketingMessage,
                DisplayBarColor.Detail
            );
        case OptInDeliveryMethods.Electronic:
            return (
                <EmailPage
                    unifiedEmailAddress={props.email}
                    confirmationEmailAddress={props.confirmationEmail}
                    submitStatus={AsyncState.Inactive}
                    handleEmailInputChange={(newValue) =>
                        props.handleEmailChange(newValue)
                    }
                    handleEmailConfirmationChange={(newValue) =>
                        props.handleConfirmationEmailChange(newValue)
                    }
                    goNext={() => props.submitEmailChange()}
                    goBack={() => props.clearSelection()}
                    customNextText={
                        translate.associate.components.associateFormInput
                            .submitButtonText
                    }
                />
            );
        default:
            return <div />;
    }
};

export default AssociateUpdateFormInput;
