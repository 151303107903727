import {
    AssociateActionTypes,
    AssociateActions
} from "../actions/AssociateActions";

import AsyncState from "../shared/enumerations/AsyncState";
import IAsyncStateContainer from "../shared/types/IAsyncStateContainer";
import { IMemberOptInDetail } from "../shared/models/Members";
import { IMemberOptInSettingsWithAgreement } from "./MemberOptInReducer";
import { Reducer } from "redux";

export interface IAssociateSearchResultState {
    associateSearchResults: IAsyncStateContainer<
        IMemberOptInSettingsWithAgreement[]
    >;
    memberDetail: IAsyncStateContainer<IMemberOptInDetail>;
    optInSubmitStatus: AsyncState;
}

const initialMemberOptInState: IAssociateSearchResultState = {
    associateSearchResults: {
        state: AsyncState.Inactive
    },
    memberDetail: {
        state: AsyncState.Inactive
    },
    optInSubmitStatus: AsyncState.Inactive
};
export const associateSearchReducer: Reducer<
    IAssociateSearchResultState,
    AssociateActions
> = (state = initialMemberOptInState, action) => {
    switch (action.type) {
        case AssociateActionTypes.GET_MEMBER_LIST: {
            return {
                ...state,
                associateSearchResults: action.associateSearchResults
            };
        }
        case AssociateActionTypes.GET_MEMBER_DETAIL: {
            return {
                ...state,
                memberDetail: action.memberDetail
            };
        }
        case AssociateActionTypes.CLEAR_UPDATE_REQUEST_STATE: {
            return {
                ...state,
                optInSubmitStatus: AsyncState.Inactive
            };
        }
        case AssociateActionTypes.SUBMIT_EMAIL_UPDATE: {
            return {
                ...state,
                optInSubmitStatus: action.submitState
            };
        }
        case AssociateActionTypes.SUBMIT_OPTIN_UPDATE: {
            return {
                ...state,
                optInSubmitStatus: action.submitState
            };
        }
        default:
            return state;
    }
};
