import DisplayBar, {
    DisplayBarColor
} from "../../../components/display-bar/DisplayBar";

import AsyncState from "../../../shared/enumerations/AsyncState";
import React from "react";

interface IConfirmationNotification {
    submitStatus: AsyncState;
    displayText: string;
}

const ConfirmationNotification: React.FC<IConfirmationNotification> = (
    props
) => {
    if (props.submitStatus === AsyncState.Success) {
        return (
            <DisplayBar color={DisplayBarColor.Success} icon="fa-check-circle">
                <p className="mb-0">{props.displayText}</p>
            </DisplayBar>
        );
    }
    return <div />;
};

export default ConfirmationNotification;
