import { IValidationState } from "./IValidationState";
import { ValidationStatus } from "../enumerations/ValidationStatus";
import { translate } from "../../translate/translate";

export const isEmailValid = (email: any): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email));
};

export const isNotEmployeeEmail = (email: any): boolean => {
    const re = /landmarkcu.com/;
    return !re.test(String(email).toLowerCase());
};

export const emailsDoMatch = (email: string, confirmation: string): boolean => {
    if (email == "") return false;
    return email === confirmation;
};

export const emailFormatValidator = (email: string): IValidationState => {
    if (email.length > 0 && isEmailValid(email)) {
        return {
            status: ValidationStatus.Valid
        };
    }
    if (email.length > 0 && !isEmailValid(email)) {
        return {
            status: ValidationStatus.InValid,
            message: translate.optInWizard.emailPage.invalidEmail
        };
    }
    return {
        status: ValidationStatus.UnTouched
    };
};

export const emailMatchValidator = (
    email: string,
    confirmation: string
): IValidationState => {
    if (confirmation.length > 0 && emailsDoMatch(email, confirmation)) {
        return {
            status: ValidationStatus.Valid
        };
    }
    if (confirmation.length > 0 && !emailsDoMatch(email, confirmation)) {
        return {
            status: ValidationStatus.InValid,
            message: translate.optInWizard.emailPage.emailsDontMatch
        };
    }
    return {
        status: ValidationStatus.UnTouched
    };
};
