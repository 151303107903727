/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-console */
import cloneDeep from "lodash.clonedeep";

/**
 * @template T the interface for the state you are modifying
 * @param state The Component State that has the property you want to modify.
 * @param propertyName The name of the property in the state you wan't to update.
 * @param newValue The new value of the state property you are updating.
 * @returns {T} A new copy of state with the properties updated. You must Set the State in your component for change to take effect.
 * @throws {Property Not found} when property is not present in state. This is a fatal error.
 *
 * @example
 * handleInputChange(name: string, newValue: any) {
        this.setState(
            tryUpdateStateWithInput<MemberLoginState>(this.state, name, newValue)
        )
    }
 */

export function tryUpdateStateWithInput<T extends {}>(
    state: T,
    propertyName: string,
    newValue: any
): T {
    if (state.hasOwnProperty(propertyName)) {
        const newState: any = cloneDeep(state);
        newState[propertyName] = newValue;

        return newState;
    } else {
        console.warn(
            "Input changing property: " +
                propertyName +
                ". But It's not found in state."
        );
    }

    throw new Error("Property Name Not Found");
}
