import { IMemberSecurityMeta } from "../shared/types/IMemberSecurityMeta";

class CookieService {
    public static readonly PUBLIC_COOKIE_KEY = "TokenAuthorization";

    public static hasAuthCookie(): boolean {
        return document.cookie.indexOf(this.PUBLIC_COOKIE_KEY) > -1;
    }

    public static getCookieValue(cookieName: string): string {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(";");
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === " ") {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return "";
    }

    public static getUserAuth(): IMemberSecurityMeta {
        const rawCookie = this.getCookieValue(this.PUBLIC_COOKIE_KEY);

        if (rawCookie) {
            const unencoded = atob(rawCookie);
            const parsed = JSON.parse(unencoded);
            const MemberSecurityMeta = <IMemberSecurityMeta>{
                isTwoFactor: parsed.IsTwoFactor,
                isMember: parsed.IsMember,
                isIndividual: parsed.IsIndividual
            };
            return MemberSecurityMeta;
        }
        return {
            isTwoFactor: false,
            isMember: false,
            isIndividual: false
        };
    }
}

export default CookieService;
