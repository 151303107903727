import {
    MemberOptInActionTypes,
    MemberOptInActions
} from "../actions/MemberOptInActions";
import AsyncState from "../shared/enumerations/AsyncState";
import IAsyncStateContainer from "../shared/types/IAsyncStateContainer";
import { Moment } from "moment";
import { Reducer } from "redux";

export interface IMemberOptInSettingsWithAgreement
    extends IMemberOptInInformation {
    readonly agreement: IAgreement;
    readonly accounts: IAgreementAccount[];
}

export interface IAgreement {
    readonly agreementNumber: number;
    readonly userId: string;
    readonly personNumber: number;
    readonly byOrgNbr: number;
    readonly organizationName: string;
    readonly firstName: string;
    readonly middleInitial: string;
    readonly middleName: string;
    readonly lastName: string;
    readonly suffix: string;
}

export interface IAgreementAccount {
    readonly accountNumber: number;
    readonly accountDescription: string;
    readonly taxReportForPersonNumber: number | null;
    readonly taxReportForOrgNumber: number | null;
    readonly ovrDeliveryMethCD: string;
    readonly accountOwner: IAgreementAccountOwner;
}

export interface IAgreementAccountOwner {
    readonly accountNumber: number;
    readonly agreementNumber: number | null;
    readonly personNumber: number | null;
    readonly userId: string;
}

export interface IMemberOptInInformation {
    readonly memberOptinSettings: IMemberOptInSettings | null;
    readonly hasPendingUpdates: boolean;
}

export enum EmailStatus {
    FailingDelivery,
    DeliveredSuccessfully
}

export interface IMemberOptInSettings {
    readonly id: number;
    readonly userId: number;
    readonly eStatements: string | null;
    readonly eNotifications: string | null;
    readonly universalOptIn: string | null;
    readonly legalVersion: string;
    readonly legalDate: Moment;
    readonly unifiedOptInEmail: string | undefined;
    readonly unifiedOptInEmailStatus?: EmailStatus;
    readonly unifiedOptInEmailFirstSent?: Date;
    readonly unifiedOptInEmailLastSent?: Date;
    readonly unifiedOptInEmailStatusCount: number;
    readonly eStatementsEmail: string | undefined;
    readonly eStatementsEmailStatus?: EmailStatus;
    readonly eStatementsEmailFirstSent?: Date;
    readonly eStatementsEmailLastSent?: Date;
    readonly eStatementsEmailStatusCount: number;
    readonly eNotificationsEmail: string | undefined;
    readonly eNotificationsEmailStatus?: EmailStatus;
    readonly eNotificationsEmailFirstSent?: Date;
    readonly eNotificationsEmailLastSent?: Date;
    readonly eNotificationsEmailStatusCount: number;
}

export interface IMemberOptInState {
    memberOptinInformation: IAsyncStateContainer<IMemberOptInInformation>;
}

const initialMemberOptInState: IMemberOptInState = {
    memberOptinInformation: {
        state: AsyncState.Inactive
    }
};
export const memberOptInReducer: Reducer<
    IMemberOptInState,
    MemberOptInActions
> = (state = initialMemberOptInState, action) => {
    switch (action.type) {
        case MemberOptInActionTypes.GET_OPTIN_PREFERENCES: {
            return {
                ...state,
                memberOptinInformation: action.memberOptinInformation
            };
        }
        default:
            return state;
    }
};
