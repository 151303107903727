import {SecurityActionTypes, SecurityActions} from "../actions/SecurityActions";
import {Reducer} from "redux";
import {UserInfo} from "react-adal";

export interface ISecurityState {
    readonly userInfo: UserInfo;
    readonly userToken: string;
}

const initialSecurityState: ISecurityState = {
    userInfo: {
        profile: {},
        userName: "Unknown"
    },
    userToken: "invalid"
};

export const securityReducer: Reducer<ISecurityState, SecurityActions> = (state = initialSecurityState, action) => {
    switch (action.type) {
        case SecurityActionTypes.APPLY_USER: {
            return {
                ...state,
                userInfo: action.user
            };
        }
        case SecurityActionTypes.APPLY_TOKEN: {
            return {
                ...state,
                userToken: action.userToken
            };
        }
        default:
            return state;
    }
};

