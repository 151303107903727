import React, { ReactElement } from "react";
import CookieService from "./../../services/CookieService";
import {RouteProps } from "react-router-dom";
import TroubleLoggingInPage from "./../status-pages/TroubleLoggingInPage";

export interface IMemberGuardedProps {
    element: ReactElement;
}

type Props = IMemberGuardedProps & RouteProps;

export default class MemberGuarded extends React.Component < Props > {
    constructor(props: IMemberGuardedProps) {
        super(props);
    }
    
    render() {
        if (CookieService.hasAuthCookie()) {
            return this.props.element;
        } else {
            return <TroubleLoggingInPage/> ;
        }

    }
}