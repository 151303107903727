import React, { ReactElement } from "react";
import "./TabView.css";

export interface ITabViewPage {
    header: string;
    element: ReactElement;
    tabOpenCallback?: () => any;
}

export interface TabViewProps {
    pages: ITabViewPage[];
    defaultPageIndex?: number;
}

interface TabViewState {
    currentPageIndex: number;
}

class TabView extends React.Component<TabViewProps, TabViewState> {
    constructor(props: TabViewProps) {
        super(props);
        this.state = {
            currentPageIndex: 0
        };
        this.callPageCallback(0);
    }

    private getClassStyle(index: number): string {
        return index === this.state.currentPageIndex
            ? "active "
            : "text-primary ";
    }

    private setCurrentPage(index: number): void {
        const newState: TabViewState = Object.assign({}, this.state);
        newState.currentPageIndex = index;
        this.setState(newState);
        this.callPageCallback(index);
    }

    private getCurrentPageElement(): ReactElement {
        const currentPage = this.props.pages[this.state.currentPageIndex];
        const defaultPageIndex = this.props.defaultPageIndex;

        if (currentPage) {
            return currentPage.element;
        }

        if (currentPage === undefined && defaultPageIndex !== undefined) {
            return this.props.pages[defaultPageIndex].element;
        }
        return <h3>Missing</h3>;
    }

    private callPageCallback(index: number) {
        if (index < this.props.pages.length) {
            const tabPageCallback = this.props.pages[index].tabOpenCallback;
            if (tabPageCallback !== undefined) {
                tabPageCallback();
            }
        }
    }

    public render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    {this.props.pages.map((page, index) => {
                        return (
                            <li key={index} className="nav-item">
                                <p
                                    className={
                                        "nav-link " + this.getClassStyle(index)
                                    }
                                    onClick={() => this.setCurrentPage(index)}
                                    data-test={"nav-link-" + index}
                                >
                                    {page.header}
                                </p>
                            </li>
                        );
                    })}
                </ul>
                {this.getCurrentPageElement()}
            </div>
        );
    }
}

export default TabView;
