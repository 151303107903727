import {
    AdalConfig,
    AuthenticationContext,
    adalFetch,
    withAdalLogin
} from "react-adal";
import { IUnifiedOptInRoleAction } from "./shared/types/IUnifiedOptInRoleAction";

export const adalConfig: AdalConfig = {
    tenant: "63cd1554-2783-4a86-ae50-b9e6c21c9499",
    clientId: "fd424561-46bb-411a-9356-19285895d4a1",
    endpoints: {
        api: "343167b9-cfb1-463b-9519-1335735077a4"
    },
    cacheLocation: "localStorage"
};

export const authContext = new AuthenticationContext(adalConfig);

authContext.handleWindowCallback();

// This is an example of getting the token, or the user.
// To get a users groups, it's getUser().profile.roles
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const getUser = () => authContext.getCachedUser();
export const getUserFullName = () => {
    const user = getUser();

    let userFullName = "Unknown User";
    if (user) {
        if (user.profile && user.profile.name) {
            userFullName = user.profile.name;
        } else if (user.userName) {
            userFullName = user.userName;
        }
    }

    return userFullName;
};

export const getUserRoles = () => {
    const user = getUser();

    if (user && user.profile && user.profile.roles) {
        return user.profile.roles.join(", ");
    }
};

export const hasRoleSearch = (
    profile: { roles?: string[] },
    roles: string[]
): boolean => {
    return !!roles.find((role) => hasRole(profile, role));
};

export const hasRole = (
    profile: { roles?: string[] },
    role: string
): boolean => {
    if (!profile || !profile.roles || profile.roles.length <= 0) {
        return false;
    }
    let hasRole = false;
    profile.roles.forEach((roleInput) => {
        if (roleInput.toLowerCase() === role.toLowerCase()) {
            hasRole = true;
        }
    });
    return hasRole;
};

export const userHasActions = (
    actions: string[],
    settingsRoleActions: IUnifiedOptInRoleAction[]
): boolean => {
    const user = getUser();

    if (
        !user ||
        !user.profile ||
        !user.profile.roles ||
        user.profile.roles.length <= 0
    ) {
        return false;
    }

    let userHasAction = false;
    const unifiedOptInRoleActions = settingsRoleActions as IUnifiedOptInRoleAction[];
    const roles = user.profile.roles as string[];

    roles.forEach((role) => {
        if (hasActions(role, actions, unifiedOptInRoleActions)) {
            userHasAction = true;
        }
    });

    return userHasAction;
};

export const hasActions = (
    role: string,
    actions: string[],
    roleActions: IUnifiedOptInRoleAction[]
): boolean => {
    const selectedRoleAction = roleActions.find(
        (roleAction) => roleAction.role === role
    );
    if (!selectedRoleAction) {
        return false;
    }

    let result = false;
    const unifiedOptInRoleAction = selectedRoleAction as IUnifiedOptInRoleAction;
    actions.forEach((action) => {
        const actionFound = unifiedOptInRoleAction.actions.find(
            (roleAction: string) => roleAction === action.toString()
        );

        if (actionFound) {
            result = true;
        }
    });

    return result;
};

export const adalApiFetch = (fetch: any, url: string, options: any) => {
    const endpointsApi = adalConfig.endpoints ? adalConfig.endpoints.api : "";
    adalFetch(authContext, endpointsApi, fetch, url, options);
};

export const authenticateToken = (): boolean => {
    if (getToken() != null) {
        // If we have a cached login, use it
        return true;
    }

    if (authContext.isCallback(window.location.hash)) {
        // This happens after the AD login screen,
        // handleWindowCallback will use the hash to
        // complete the login
        authContext.handleWindowCallback();
        return true;
    }

    // Not logged in
    return false;
};

export const withAdalLoginApi = withAdalLogin(
    authContext,
    adalConfig.endpoints ? adalConfig.endpoints.api : ""
);
