import React from "react";
import DisplayBar, {
    DisplayBarColor
} from "../../../components/display-bar/DisplayBar";
import { IMemberOptInDisplayDetails } from "../../../services/MemberOptInService";
import { translate } from "../../../translate/translate";

export interface IUnifiedPreferenceDisplayProps {
    unifiedDisplayDetails: IMemberOptInDisplayDetails;
    hasUnifiedPreference?: boolean;
}

const UnifiedPreferenceDisplay: React.FC<IUnifiedPreferenceDisplayProps> = (
    props: IUnifiedPreferenceDisplayProps
) => {
    if (props.hasUnifiedPreference === true) {
        return (
            <div data-test="unified-preference">
                <DisplayBar color={DisplayBarColor.Detail} icon="" center>
                    <p className="mb-0 text-center">
                        {translate.memberStartPage.deliveryPreferenceLabel}
                        <span className="font-weight-bold text-info">
                            {props.unifiedDisplayDetails.deliveryMethod}
                        </span>
                    </p>
                    <p className="mb-0 text-center">
                        {props.unifiedDisplayDetails.deliveryMethodDisplayText}{" "}
                        <span className="font-weight-bold text-info">
                            {props.unifiedDisplayDetails.email}
                        </span>
                    </p>
                </DisplayBar>
            </div>
        );
    }

    return null;
};

export default UnifiedPreferenceDisplay;
