import React from "react";
import logo from "../../assets/LMCU-White-Logo-Only.png";

export interface NavigationBarProps {
    linkCallback?: (selection: Element) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top d-flex flex-row align-content-center">
            <a className="navbar-brand title-main">
                <img
                    src={logo}
                    style={{ width: "1.5em" }}
                    alt="Logo"
                    className="logo-main mr-3"
                />{" "}
                Member Preferences Tool
            </a>
        </nav>
    );
};

export default NavigationBar;
