import devEnvironment from "./environment.dev";

const uatEnvironment = {
    ...devEnvironment,
    name: "uat",
    requiredRole: "QaAssociate",
    production: true,
    useLocalSettings: false,
    enableTimeout: true,
    baseUrls: {
        vendorApi: "https://uat-vendor-api.landmarkcu.com",
        landmarkGetMedia: "https://landmarkcu.com/getmedia"
    }
};

export default uatEnvironment;
