import React, { ReactElement } from "react";
import { authContext, authenticateToken, getUser } from "../../adalConfig";
import AccessDeniedPage from "../status-pages/AccessDeniedPage";
import { RouteProps } from "react-router-dom";
import { hasRoleSearch } from "./../../adalConfig";

export interface IAdalGuardedProps {
    element: ReactElement;
    allowedRoles?: string[];
}

export interface IAdalGuardedState {
    loggedIn: boolean;
}

type Props = IAdalGuardedProps & RouteProps;

class AdalGuarded extends React.Component<Props, IAdalGuardedState> {
    constructor(props: IAdalGuardedProps) {
        super(props);
        this.state = {
            loggedIn: false
        };
    }

    componentDidMount() {
        if (authenticateToken()) {
            this.setState({
                loggedIn: true
            });
        } else {
            authContext.clearCache();
            authContext.login();
        }
    }

    private checkRoles(): boolean {
        if (this.props.allowedRoles) {
            return hasRoleSearch(getUser().profile, this.props.allowedRoles);
        }

        return true;
    }

    render() {
        if (this.state.loggedIn && this.checkRoles()) {
            return this.props.element;
        } else {
            return <AccessDeniedPage />;
        }
    }
}

export default AdalGuarded;
