/* eslint-disable @typescript-eslint/ban-types */

import { ActionCreator, Dispatch } from "redux";
import {
    submitMemberOptIn,
    submitMemberOptOut
} from "../services/MemberOptInService";

import AsyncState from "../shared/enumerations/AsyncState";
import { IAppState } from "../store/Store";
import { ThunkAction } from "redux-thunk";

export enum MemberOptInFormActionTypes {
    TOGGLE_MEMBER_ACCEPTANCE = "TOGGLE_USER_ACCEPTANCE",
    UPDATE_MEMBER_OPTIN_EMAIL = "UPDATE_MEMBER_OPTIN_EMAIL",
    UPDATE_MEMBER_CONFIRMATION_EMAIL = "UPDATE_MEMBER_CONFIRMATION_EMAIL",
    SUBMIT_MEMBER_OPTIN_FORM = "SUBMIT_MEMBER_OPTIN_FORM",
    CLEAR_MEMBER_OPTIN_FORM = "CLEAR_MEMBER_OPTIN_FORM",
    SUBMIT_MEMBER_OPTOUT = "SUBMIT_MEMBER_OPTOUT"
}

export interface IToggleMemberAcceptanceAction {
    type: MemberOptInFormActionTypes.TOGGLE_MEMBER_ACCEPTANCE;
}

export interface IUpdateMemberOptInEmailAction {
    type: MemberOptInFormActionTypes.UPDATE_MEMBER_OPTIN_EMAIL;
    unifiedOptInEmail: string;
}

export interface IUpdateMemberConfirmationEmailAction {
    type: MemberOptInFormActionTypes.UPDATE_MEMBER_CONFIRMATION_EMAIL;
    confirmationEmail: string;
}

export interface ISubmitMemberOptInFormAction {
    type: MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTIN_FORM;
    submitResults: AsyncState;
}

export interface IClearMemberOptInFormAction {
    type: MemberOptInFormActionTypes.CLEAR_MEMBER_OPTIN_FORM;
}

export interface ISubmitMemberOptOutAction {
    type: MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTOUT;
    submitResults: AsyncState;
}

export type MemberOptInFormActions =
    | IToggleMemberAcceptanceAction
    | IUpdateMemberOptInEmailAction
    | IUpdateMemberConfirmationEmailAction
    | ISubmitMemberOptInFormAction
    | ISubmitMemberOptOutAction
    | IClearMemberOptInFormAction;

export const toggleMemberAcceptance: ActionCreator<IToggleMemberAcceptanceAction> = () => {
    return {
        type: MemberOptInFormActionTypes.TOGGLE_MEMBER_ACCEPTANCE
    };
};

export const updateMemberOptInEmail: ActionCreator<IUpdateMemberOptInEmailAction> = (
    newValue: string
) => {
    return {
        type: MemberOptInFormActionTypes.UPDATE_MEMBER_OPTIN_EMAIL,
        unifiedOptInEmail: newValue
    };
};

export const updateMemberConfirmationEmail: ActionCreator<IUpdateMemberConfirmationEmailAction> = (
    newValue: string
) => {
    return {
        type: MemberOptInFormActionTypes.UPDATE_MEMBER_CONFIRMATION_EMAIL,
        confirmationEmail: newValue
    };
};

export const clearMemberOptInForm: ActionCreator<IClearMemberOptInFormAction> = () => {
    return {
        type: MemberOptInFormActionTypes.CLEAR_MEMBER_OPTIN_FORM
    };
};

export const submitMemberOptInForm: ActionCreator<ThunkAction<
    Promise<any>,
    IAppState,
    {},
    ISubmitMemberOptInFormAction
>> = () => {
    return async (dispatch: Dispatch, getState) => {
        dispatch({
            type: MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTIN_FORM,
            submitResults: AsyncState.Loading
        });

        const results = await submitMemberOptIn(
            getState().memberOptInRequestState.memberOptInFormData
                .unifiedOptinEmail
        );

        dispatch({
            type: MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTIN_FORM,
            submitResults: results
        });
    };
};

export const submitMemberOptOutForm: ActionCreator<ThunkAction<
    Promise<any>,
    IAppState,
    {},
    ISubmitMemberOptInFormAction
>> = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTOUT,
            submitResults: AsyncState.Loading
        });

        const results = await submitMemberOptOut();

        dispatch({
            type: MemberOptInFormActionTypes.SUBMIT_MEMBER_OPTOUT,
            submitResults: results
        });
    };
};
