import { translate } from "../../translate/translate";
import moment from "moment";
import { IMemberPreference } from "../../shared/models/Members";
import React, { FC } from "react";
import { AutoTable } from "../auto-table/AutoTable";

interface PreferenceTableProps {
    historyData: IMemberPreference[];
}

const PreferenceTable: FC<PreferenceTableProps> = (
    props: PreferenceTableProps
) => {
    const dateFormat =
        translate.associate.components.preferenceTable.dateFormat;
    const headers = [
        translate.associate.components.preferenceTable.headers.preference,
        translate.associate.components.preferenceTable.headers.newValue,
        translate.associate.components.preferenceTable.headers.dateTime,
        translate.associate.components.preferenceTable.headers.user,
        translate.associate.components.preferenceTable.headers.userType,
        translate.associate.components.preferenceTable.headers.legal
    ];

    const safeDate = (input: moment.Moment | null | undefined | string) => {
        if (input && moment.isMoment(input)) {
            return input.local().format(dateFormat);
        }
        return input;
    };

    const mapPreferenceName = (preference: string) => {
        switch (preference) {
            case "ENotification":
                return "ENotices";
            case "ENotificationsEmail":
                return "ENoticesEmail";
            default:
                return preference;
        }
    };

    const cells = [
        (x: IMemberPreference) => mapPreferenceName(x.preference),
        (x: IMemberPreference) => x.value,
        (x: IMemberPreference) => safeDate(x.created),
        (x: IMemberPreference) => x.createdBy,
        (x: IMemberPreference) => x.createdByType,
        (x: IMemberPreference) => x.legalReference
    ];

    return (
        <AutoTable
            elements={props.historyData || []}
            headers={headers}
            cells={cells}
            data-test="preference-table-wrapper"
        />
    );
};

export default PreferenceTable;
