interface IUrlParam {
    key: string;
    value: string | number | boolean;
}

export default class UrlFormatting {
    /**
     * Replace the variable parameters of a url with their values
     * 
     * I.E.
     * 
     * ReplaceURLParams(
     *      "http://www.test.com/foo/{fooId}/bar/{barId}",
     *      { key: 'fooId', value: 1 },
     *      { key: barId, value: 'second_param' }
     * );
     * 
     * Would result in a return value of:
     *      "http://www.test.com/foo/1/bar/second_param"
     * 
     * NOTE: This method is case sensitive
     * 
     * @param url The base URL
     * @param params Each param with it's token and value
     * @returns A paramterized version of the URL
     */
    public static ReplaceURLParams(url: string, ...params: IUrlParam[]) {
        for (const param of params) {
            url = url.replace(`{${param.key}}`, param.value + "");
        }
        return url;
    }
}