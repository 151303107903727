import "./App.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import AdalGuarded from "./containers/adal-guarded/AdalGuarded";
import { AppRouteHistory } from "./store/Store";
import AssociateMemberDetailPage from "./containers/associate-page/AssociateMemberDetailPage";
import AssociatePage from "./containers/associate-page/AssociatePage";
import { ConnectedRouter } from "connected-react-router";
import ErrorPage from "./containers/status-pages/ErrorPage";
import MemberGuarded from "./containers/member-guarded/MemberGuarded";
import MemberLogin from "./containers/member-login/MemberLogin";
import MemberStartPage from "./containers/member-start-page/MemberStartPage";
import NotFound404Page from "./containers/status-pages/NotFound404Page";
import OptInWizard from "./containers/unified-wizard/UnifiedWizard";
import React from "react";
import Routes from "./shared/Routes";
import Configuration from "./shared/configuration/Configuration";

function App() {
    return (
        <ConnectedRouter history={AppRouteHistory}>
            <HashRouter>
                <Switch>
                    <Route
                        exact
                        path={Routes.associate}
                        render={(routerProps) => (
                            <AdalGuarded
                                allowedRoles={[Configuration.Get.requiredRole]}
                                element={<AssociatePage {...routerProps} />}
                                {...routerProps}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={Routes.associateMemberView}
                        render={(routerProps) => (
                            <AdalGuarded
                                allowedRoles={[Configuration.Get.requiredRole]}
                                element={
                                    <AssociateMemberDetailPage
                                        {...routerProps}
                                    />
                                }
                                {...routerProps}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={Routes.root}
                        render={(routerProps) => (
                            <MemberGuarded
                                element={<MemberStartPage {...routerProps} />}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={Routes.memberOptIn}
                        render={(routerProps) => (
                            <MemberGuarded
                                element={<OptInWizard {...routerProps} />}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={Routes.memberlogin}
                        render={(routerProps) => (
                            <MemberLogin {...routerProps} />
                        )}
                    />
                    <Route
                        exact
                        path={Routes.error}
                        render={(routerProps) => <ErrorPage {...routerProps} />}
                    />
                    <Route
                        exact
                        path={Routes.defaultError}
                        render={(routerProps) => <ErrorPage {...routerProps} />}
                    />
                    <Route
                        exact
                        path="*"
                        render={(routerProps) => (
                            <NotFound404Page {...routerProps} />
                        )}
                    />
                </Switch>
            </HashRouter>
        </ConnectedRouter>
    );
}

export default App;
