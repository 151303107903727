import "./StatusPage.scss";

import { RouteComponentProps } from "react-router-dom";
import React from "react";
import logo from "../../assets/LMCU-Blue-Logo-Only.png";
import { translate } from "./../../translate/translate";

interface MatchParams {
    message: string;
}

type Props = RouteComponentProps & RouteComponentProps<MatchParams>;

class ErrorPage extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        const {
            match: { params }
        } = this.props;

        if (params?.message) {
            const message = atob(params.message);
            console.error(message);
        }
    }

    render() {
        return (
            <div className="status-page container">
                <div className="d-flex flex-row justify-content-center">
                    <img src={logo} className="center" />
                </div>
                <h1 className="pt-2">{translate.errorPages.errorPage.title}</h1>
                <p>{translate.errorPages.errorPage.subText}</p>
            </div>
        );
    }
}

export default ErrorPage;
