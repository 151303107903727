import "./AssociatePage.css";

import AsyncState from "../../shared/enumerations/AsyncState";
import Container from "../../components/layout/Container";
import Footer from "./../../components/footer/Footer";
import { IAppState } from "../../store/Store";
import IAsyncStateContainer from "../../shared/types/IAsyncStateContainer";
import { IMemberOptInSettingsWithAgreement } from "../../reducers/MemberOptInReducer";
import MemberSearchForm from "../../components/associate/search/MemberSearchForm";
import MemberSearchResults from "../../components/associate/search/MemberSearchResults";
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import React from "react";
import { RouteComponentProps } from "react-router";
import { RouteProps } from "react-router-dom";
import Routes from "../../shared/Routes";
import { connect } from "react-redux";
import { queryOptInMembers } from "../../actions/AssociateActions";
import { translate } from "../../translate/translate";

interface IPropsFromState {
    associateSearchResults: IAsyncStateContainer<
        IMemberOptInSettingsWithAgreement[]
    >;
}

interface IPropsFromDispatch {
    queryOptInMembers: (query: string) => any;
}

const mapStateToProps = (appState: IAppState): IPropsFromState => {
    return {
        associateSearchResults: appState.associateState.associateSearchResults
    };
};

const mapDispatchToProps: IPropsFromDispatch = {
    queryOptInMembers: queryOptInMembers
};

type AssociatePageProps = RouteProps &
    IPropsFromDispatch &
    IPropsFromState &
    RouteComponentProps;

const AssociatePage: React.FC<AssociatePageProps> = (
    props: AssociatePageProps
) => {
    const SearchFormHandler = (query: string): void => {
        if (props.associateSearchResults.state !== AsyncState.Loading) {
            query = query.trim();
            props.queryOptInMembers(query);
        }
    };

    const SelectMemberHandler = (
        member: IMemberOptInSettingsWithAgreement
    ): void => {
        props.history.push(
            Routes.associateMemberView.replace(
                ":userId",
                member.agreement.userId + ""
            )
        );
    };

    return (
        <div data-test="associate-top-level">
            <NavigationBar />
            <Container data-test="associate-container">
                <h1>{translate.associate.containers.landing.page}</h1>
                <h3>{translate.associate.containers.landing.search}</h3>
                <MemberSearchForm
                    searchHandler={SearchFormHandler}
                    waitState={props.associateSearchResults.state}
                />
                <div className="containerIntro">
                    <h3 className="pt-2">
                        {translate.associate.containers.landing.results}
                    </h3>
                    <p className="legendText">
                        {
                            translate.associate.containers.landing
                                .accountAsteriskDetails
                        }
                    </p>
                </div>
                <MemberSearchResults
                    memberSelectHandler={SelectMemberHandler}
                    searchResults={props.associateSearchResults}
                />
            </Container>
            <Footer />
        </div>
    );
};

export const _AssociatePage = AssociatePage;
// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<IPropsFromState, IPropsFromDispatch, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
)(AssociatePage);
