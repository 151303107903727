import { IValidationState } from "../../../shared/validators/IValidationState";
import React from "react";
import ValidatedInput from "./../../../components/validated-input/ValidatedInput";
import { isEmailValid } from "../../../shared/validators/EmailValidators";

export interface IEmailInputProps {
    unifiedEmailAddress: string;
    confirmationEmailAddress: string;
    emailFormatValidation: () => IValidationState;
    emailMatchValidation: () => IValidationState;
    handleEmailInputChange: (newValue: string) => void;
    handleEmailConfirmationChange: (newValue: string) => void;
}

const EmailInput: React.FC<IEmailInputProps> = (props: IEmailInputProps) => {
    return (
        <form>
            <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <p className="mb-0">Email Address</p>
                <ValidatedInput
                    validationState={props.emailFormatValidation()}
                    data-test="email-input"
                >
                    <input
                        style={{ minWidth: "200px" }}
                        className="form-control"
                        placeholder="Email"
                        value={props.unifiedEmailAddress}
                        onChange={(event) => {
                            props.handleEmailInputChange(event.target.value);
                        }}
                    />
                </ValidatedInput>
                <p className="mb-0">Confirm Email Address</p>
                <ValidatedInput
                    validationState={props.emailMatchValidation()}
                    data-test="confirmation-input"
                >
                    <input
                        style={{ minWidth: "200px" }}
                        className="form-control"
                        placeholder="Email"
                        value={props.confirmationEmailAddress}
                        onChange={(event) => {
                            props.handleEmailConfirmationChange(
                                event.target.value
                            );
                        }}
                        disabled={!isEmailValid(props.unifiedEmailAddress)}
                    />
                </ValidatedInput>
            </div>
        </form>
    );
};

export default EmailInput;
