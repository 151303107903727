import {DeepPartial} from "redux";
import {IAppState} from "../store/Store";
import pick from "lodash.pick";


class LocalStorageService {

    private static StorageKey = "ApplicationState";

    public static loadAppSavedState(paths?: string[]): DeepPartial<IAppState> {
        try {
            const serializedState = localStorage.getItem(LocalStorageService.StorageKey);
            if (serializedState === null) {
                return {};
            }
            const parsed = JSON.parse(serializedState);
            return paths ? pick(parsed, paths) : parsed;
        } catch (err) {
            return {};
        }
    }

    public static saveAppState(state: IAppState, paths?: string[]) {
        try {
            const targetObj = paths ? pick(state, paths) : state;
            const serializedState = JSON.stringify(targetObj);
            localStorage.setItem(LocalStorageService.StorageKey, serializedState);
        } catch {
            // Not logging because this can happen for normal reasons.
        }
    }
}

export default LocalStorageService;
