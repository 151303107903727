import "./DisplayBar.scss";

import React from "react";

export enum DisplayBarColor {
    Success = "success",
    Detail = "detail",
    Danger = "danger",
    Warning = "warn",
    Error = "error"
}

interface IDisplayBarProps {
    icon: string;
    color: DisplayBarColor;
    center?: boolean;
}

const DisplayBar: React.FC<IDisplayBarProps> = (props) => {
    return (
        <div
            className={`d-flex flex-row align-items-center text-${
                props.color
            } p-2 rounded ${props.color} mb-2 ${
                props.center === true ? "justify-content-center" : ""
            }`}
        >
            <p className={`fa ${props.icon} mb-0`} />
            <div className="mb-0 ml-3">{props.children}</div>
        </div>
    );
};

export default DisplayBar;
