import Configuration from "../shared/configuration/Configuration";
import { MemberOptInTypes } from "../shared/enumerations/MemberOptInSettingsTypes";
import UrlFormatting from "../shared/formatting/UrlFormatting";

//In the future we may be able to use UUID to version these documents. This service will allow us to use the UUID to grab the appropriate document.
export const getUrlForTermsOfServiceByType = (type : MemberOptInTypes) : string => {
    switch(type){
        default:
            let termsOfServiceUrl  =  Configuration.Get.baseUrls.landmarkGetMedia + Configuration.Get.urls.landmarkGetMedia.eStatementsDisclosures.url;
            termsOfServiceUrl = UrlFormatting.ReplaceURLParams(termsOfServiceUrl, 
                {
                key: "uuid",
                value: Configuration.Get.urls.landmarkGetMedia.eStatementsDisclosures.uuid
                }
            );
            
            return termsOfServiceUrl;
    }
};