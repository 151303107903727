import "./MenuButton.scss";
import React from "react";

interface IMenuButtonProps {
    icon: string;
    text: string;
    markSelected: () => void;
    selected?: boolean;
    disabled?: boolean;
}

const MenuButton: React.FC<IMenuButtonProps> = (props) => {
    if (props.selected === true) {
        return (
            <div>
                <div
                    onClick={() => props.markSelected()}
                    className="p-3 d-flex flex-row justify-content-start align-items-start menu-button mb-3  bg-primary text-white"
                    data-test="onclick-div"
                >
                    <div>
                        <p
                            className={`icon fa ${props.icon} p-0 mb-0 mr-5 text-center`}
                        ></p>
                    </div>
                    <div className="mr-3">
                        <p className="mb-0">
                            <b>{props.text}</b>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (props.disabled == true) {
        return (
            <div
                onClick={() => {
                    null;
                }}
            >
                <div className="p-3 d-flex flex-row justify-content-start align-items-start menu-button mb-3 disabled">
                    <div>
                        <p
                            className={`icon fa ${props.icon} p-0 mb-0 mr-5 text-center`}
                        ></p>
                    </div>
                    <div className="mr-3">
                        <p className="mb-0">
                            <b>{props.text}</b>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div
                className="p-3 d-flex flex-row justify-content-start align-items-start menu-button mb-3"
                onClick={() => props.markSelected()}
                data-test="onclick-div"
            >
                <div>
                    <p
                        className={`icon fa ${props.icon} p-0 mb-0 mr-5 text-center`}
                    ></p>
                </div>
                <div className="mr-3">
                    <p className="mb-0">
                        <b>{props.text}</b>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MenuButton;
