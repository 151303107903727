import "./NotificationBar.scss";

import React from "react";

export enum NotificationBarType {
    Success = "success",
    Warning = "warning",
    Danger = "danger",
    Loading = "primary",
    Info = "info",
    Disabled = "secondary"
}

interface INotificationBarProps {
    type: NotificationBarType;
    text: string;
    subText?: string;
    isButtonPresent?: boolean;
    buttonText?: string;
    fontAwesomeIcon?: string;
    onButtonClick?: () => any;
}

const getNotificationIcon = (alertType: NotificationBarType, fontAwesomeIcon?: string) => {
    if (fontAwesomeIcon) {
        return <i className={"fa " + fontAwesomeIcon} data-test="misc-icon"></i>;
    }
    switch (alertType) {
        case NotificationBarType.Loading:
            return (
                <i className="fa fa-spinner fa-spin" data-test="loading-icon"></i>
            );
        case NotificationBarType.Danger:
            return (
                <i className="fa fa-times-circle" data-test="danger-icon"></i>
            );
        case NotificationBarType.Warning:
            return (
                <i className="fa fa-exclamation-triangle" data-test="warning-icon"></i>
            );
        case NotificationBarType.Success:
            return (
                <i className="fa fa-check-circle" data-test="success-icon"></i>
            );
        case NotificationBarType.Info:
            return (
                <i className="fa fa-info-circle" data-test="info-icon"></i>
            );
        case NotificationBarType.Disabled:
            return (
                <i className="fa fa-exclamation-circle" data-test="exclamation-icon"></i>
            );
    }
};

const NotificationBar: React.FC<INotificationBarProps> = (props) => {

    const callDismissCallback = () => {
        if (props.onButtonClick) {
            props.onButtonClick();
        }
    };

    const getbuttonText = () => {
        if (props.isButtonPresent) {
            return props.buttonText || "Dismiss";
        }
        return "";
    };

    return (
        <div className={"notification-bar alert alert-" + props.type} role="alert" data-test="notification-bar">
            {getNotificationIcon(props.type, props.fontAwesomeIcon)}
            {props.text}
            {props.isButtonPresent ?   
                <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={callDismissCallback}
                    data-test="notification-bar-dismiss-button"
                >
                    <span className="notification-bar-button-text">{getbuttonText()}</span>
                </button>
            : null}
            {props.subText ?
                <div className="notification-bar-sub-text-container">
                    <hr></hr>
                    <p><b>{props.subText}</b></p>
                </div>
            : null}
        </div>
    );
};

export default NotificationBar;
