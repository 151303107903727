import React from "react";
import {
    EmailStatus,
    IMemberOptInSettingsWithAgreement
} from "../../reducers/MemberOptInReducer";
import { translate } from "../../translate/translate";
import "./MemberTableInformation.scss";

interface IEmailDeliveryStatus {
    readonly emailStatus?: EmailStatus;
    readonly firstSent?: Date;
    readonly lastSent?: Date;
    readonly attempts?: number;
}

interface ITableRowProps extends IEmailDeliveryStatus {
    readonly dataTestType: "eStatements" | "eNotifications" | "unifiedOptin";
    readonly type: "eStatements" | "eNotices" | "Unified";
    readonly preference?: string | null;
    readonly email?: string | null;
}

const TableRow = ({
    dataTestType,
    type,
    preference,
    email,
    ...deliveryStatus
}: ITableRowProps) => {
    const defaultCellValue = (value?: string | null) =>
        value
            ? value
            : translate.associate.components.memberPreferenceTable.noValue;

    const deliveryStatusDisplay = ({
        emailStatus,
        firstSent,
        lastSent,
        attempts
    }: IEmailDeliveryStatus): string => {
        const na = "N/A";

        if (preference === "Paper") return na;

        if (emailStatus === EmailStatus.DeliveredSuccessfully)
            return `Success: ${lastSent!.toLocaleDateString()}`;
        if (emailStatus === EmailStatus.FailingDelivery)
            return `Undeliverable: ${firstSent!.toLocaleDateString()} - ${lastSent!.toLocaleDateString()} Attempts: ${attempts}`;

        return na;
    };

    return (
        <tr
            data-test={`member-${dataTestType}-information`}
            className={
                deliveryStatus.emailStatus === EmailStatus.FailingDelivery
                    ? "undeliverable"
                    : ""
            }
        >
            <td>{type}</td>
            <td>{defaultCellValue(preference)}</td>
            <td>{defaultCellValue(email)}</td>
            <td>{deliveryStatusDisplay(deliveryStatus)}</td>
        </tr>
    );
};

export class MemberTableInformation extends React.Component<{
    memberData: IMemberOptInSettingsWithAgreement;
}> {
    constructor(props: { memberData: IMemberOptInSettingsWithAgreement }) {
        super(props);
    }

    render() {
        const memberData = this.props.memberData.memberOptinSettings;
        if (!memberData?.universalOptIn) {
            return (
                <>
                    <TableRow
                        dataTestType="eStatements"
                        type="eStatements"
                        preference={memberData?.eStatements}
                        email={memberData?.eStatementsEmail}
                        emailStatus={memberData?.eStatementsEmailStatus}
                        firstSent={memberData?.eStatementsEmailFirstSent}
                        lastSent={memberData?.eStatementsEmailLastSent}
                        attempts={memberData?.eStatementsEmailStatusCount}
                    />
                    <TableRow
                        dataTestType="eNotifications"
                        type="eNotices"
                        preference={memberData?.eNotifications}
                        email={memberData?.eNotificationsEmail}
                        emailStatus={memberData?.eNotificationsEmailStatus}
                        firstSent={memberData?.eNotificationsEmailFirstSent}
                        lastSent={memberData?.eNotificationsEmailLastSent}
                        attempts={memberData?.eNotificationsEmailStatusCount}
                    />
                </>
            );
        } else {
            return (
                <TableRow
                    dataTestType="unifiedOptin"
                    type="Unified"
                    preference={memberData.universalOptIn}
                    email={memberData.unifiedOptInEmail}
                    emailStatus={memberData?.unifiedOptInEmailStatus}
                    firstSent={memberData?.unifiedOptInEmailFirstSent}
                    lastSent={memberData?.unifiedOptInEmailLastSent}
                    attempts={memberData?.unifiedOptInEmailStatusCount}
                />
            );
        }
    }
}
