import "./SelectFlowPage.scss";

import DisplayBar, {
    DisplayBarColor
} from "../../../components/display-bar/DisplayBar";

import AsyncState from "../../../shared/enumerations/AsyncState";
import EmailPage from "../email-page/EmailPage";
import MenuButton from "../../../components/menu-button/MenuButton";
import React from "react";
import { UnifiedWizardFlows } from "./../UnifiedWizard";
import WizardButtonControl from "../../../components/wizard-button-control/WizardButtonControl";
import WizardPageContainer from "./../wizard-page-container/WizardPageContainer";
import { translate } from "../../../translate/translate";

export interface ISelectFlowPageProps {
    unifiedEmailAddress: string;
    confirmationEmailAddress: string;
    submitStatus: AsyncState;
    selectedFlow: UnifiedWizardFlows;
    goBack: () => any;
    goNextOptIn: () => any;
    goNextOptOut: () => any;
    handleEmailInputChange: (newValue: string) => any;
    handleEmailConfirmationChange: (newValue: string) => any;
    updateSelectedFlow: (newValue: UnifiedWizardFlows) => any;
}

type Props = ISelectFlowPageProps;

// eslint-disable-next-line @typescript-eslint/ban-types
class SelectFlowPage extends React.Component<ISelectFlowPageProps> {
    constructor(props: Props) {
        super(props);
    }

    isPaperSelected(): boolean {
        return this.props.selectedFlow === UnifiedWizardFlows.Paper;
    }

    isElectronicSelected(): boolean {
        return this.props.selectedFlow === UnifiedWizardFlows.Electronic;
    }

    errorMessageDisplay = (): any => {
        if (this.props.submitStatus == AsyncState.Failure) {
            return (
                <div
                    className="container text-center mt-1"
                    data-test="error-message"
                >
                    <p className="text-danger">
                        {translate.optInWizard.selectionPage.submitErrorMessage}
                    </p>
                </div>
            );
        }
    };

    loadingMessageDisplay = (): any => {
        if (this.props.submitStatus === AsyncState.Loading) {
            return (
                <div
                    className="container text-center mt-1"
                    data-test="loading-message"
                >
                    <p className="mb-0 fa fa-spinner fa-spin"></p>{" "}
                    {translate.optInWizard.selectionPage.loadingMessage}
                </div>
            );
        }
    };

    marketingMessageDisplay = (): any => {
        if (this.isPaperSelected()) {
            return (
                <DisplayBar
                    color={DisplayBarColor.Detail}
                    icon="fa-info-circle"
                >
                    <p className="mb-0">
                        {
                            translate.optInWizard.selectionPage
                                .paperMarketingMessage
                        }
                    </p>
                </DisplayBar>
            );
        }
    };

    render() {
        return (
            <WizardPageContainer
                pageTitle={translate.optInWizard.selectionPage.pageTitle}
                pageDescription={
                    translate.optInWizard.selectionPage.pageInformation
                }
            >
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <div className="d-flex flex-wrap justify-content-around align-items-stretch mb-3">
                        <MenuButton
                            icon={
                                translate.preferences
                                    .deliveryMethodFontAwesomeIcon.electronic
                            }
                            text={
                                translate.optInWizard.selectionPage
                                    .electronicSelection
                            }
                            markSelected={() =>
                                this.props.updateSelectedFlow(
                                    UnifiedWizardFlows.Electronic
                                )
                            }
                            selected={this.isElectronicSelected()}
                        />

                        <MenuButton
                            icon={
                                translate.preferences
                                    .deliveryMethodFontAwesomeIcon.paper
                            }
                            text={
                                translate.optInWizard.selectionPage
                                    .paperSelection
                            }
                            markSelected={() =>
                                this.props.updateSelectedFlow(
                                    UnifiedWizardFlows.Paper
                                )
                            }
                            selected={this.isPaperSelected()}
                        />
                    </div>
                </form>
                {!this.isElectronicSelected() ? (
                    <div>
                        {this.marketingMessageDisplay()}
                        <WizardButtonControl
                            customNextText={
                                translate.optInWizard.selectionPage.submitButton
                            }
                            disableNext={
                                !this.isElectronicSelected() &&
                                !this.isPaperSelected()
                            }
                            goNext={() => this.props.goNextOptOut()}
                            goBack={() => this.props.goBack()}
                        />
                        {this.loadingMessageDisplay()}
                        {this.errorMessageDisplay()}
                    </div>
                ) : (
                    <div>
                        <EmailPage
                            unifiedEmailAddress={this.props.unifiedEmailAddress}
                            confirmationEmailAddress={
                                this.props.confirmationEmailAddress
                            }
                            handleEmailInputChange={(newValue) => {
                                this.props.handleEmailInputChange(newValue);
                            }}
                            handleEmailConfirmationChange={(newValue) => {
                                this.props.handleEmailConfirmationChange(
                                    newValue
                                );
                            }}
                            submitStatus={this.props.submitStatus}
                            goBack={() => this.props.goBack()}
                            goNext={() => this.props.goNextOptIn()}
                        />
                    </div>
                )}
            </WizardPageContainer>
        );
    }
}

export default SelectFlowPage;
