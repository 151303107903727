export const translate = {
    associate: {
        containers: {
            landing: {
                page: "Home",
                search: "Search by Account Number",
                results: "Search Results",
                accountAsteriskDetails: "* Denotes Controlling Person",
                copy: "Associate Copy Goes Here. You can use the form below to search for a member."
            },
            detail: {
                warning: {
                    title: "No Member Id Provided",
                    body: "A valid member Id is required to view member details.",
                    hasPendingUpdates:
                        "Recent changes to Member's OptIn Settings are still Pending. This may prevent updates from saving at this time."
                },
                title: "eAgreement Detail",
                historyTitle: "Preference History",
                backButton: "Go Back",
                personLabel: "Person / [Organization]",
                accountNumbersLabel: "Account Number(s)",
                colonAndSpace: ": ",
                successfulSubmitNotification:
                    "Successfully Updated Member Preference. ENotices and EStatements may take some time to reflect this change.",
                copy: "You can view member preference details and history from this page. Below is an example of what this member sees. Below that are the specific field values."
            }
        },
        components: {
            memberPreferenceTable: {
                noValue: "No Value"
            },
            searchForm: {
                query: "Search Account Number",
                submitButton: "Search"
            },
            memberTable: {
                dateFormat: "MM/DD/YYYY hh:MM:SS A",
                headers: {
                    person: "Person / [Organization]",
                    accountNumbers: "Account Number(s)",
                    eNotices: "eNotices",
                    eStatements: "eStatements",
                    unified: "Unified",
                    created: "Created",
                    lastUpdated: "Last Updated"
                },
                values: {
                    unknown: "Unknown"
                }
            },
            memberSearchResults: {
                loading: "Please wait, searching ...",
                failure:
                    "Unable to search members, something went wrong. Please try refreshing your browser and contact IT if the problem persists.",
                empty: "No results were found, please try again."
            },
            memberPreferenceHistory: {
                loading: "Please wait, loading ...",
                failure:
                    "Unable to load member, something went wrong. Please try refreshing your browser and contact IT if the problem persists..",
                empty: "No member result found."
            },
            preferenceTable: {
                dateFormat: "MM/DD/YYYY hh:mm:ss A",
                headers: {
                    preference: "Preference",
                    newValue: "New Value",
                    dateTime: "Date/Time",
                    user: "User",
                    userType: "User Type",
                    legal: "Legal"
                }
            },
            associateUpdateForm: {
                paperButton: "Update Preference to Paper",
                updateEmailButton: {
                    text: "Update Email",
                    icon: "fa-address-card"
                },
                loadingMessage: "Submitting Changes...",
                errorMessage:
                    "Failed to submit changes, something went wrong. Please check your internet connection or contact IT. "
            },
            associateFormInput: {
                toPaperMarketingMessage:
                    "Some Landmark accounts offer a promotional rate or bonus when you chose electronic delivery.  Choosing paper may impact your specific promotion.  Please refer to the account and truth in savings disclosure for more information.",
                submitButtonText: "Submit Update"
            }
        }
    },
    preferences: {
        displayNames: {
            eNotifications: "Landmark Notices",
            eStatements: "Landmark Statements",
            unifiedOptIn: "Unified Opt In"
        },
        descriptions: {
            eNotifications:
                "Get notified when new notices and other documents are ready to view in online banking.",
            eStatements:
                "Get notified when new banking statements are ready to view in online banking.",
            unifiedOptIn: "Set your preferences for all Landmark notifications."
        },
        deliveryMethodText: {
            electronic: "Electronic",
            paper: "Paper",
            default: "Opted In",
            none: "None"
        },
        deliveryMethodFriendlyText: {
            estatementsPaper: "Paper",
            eNotificationsPaper: "Paper",
            electronic: "Electronic - Your notifications are being sent to:",
            default: "Yes"
        },
        deliveryMethodFontAwesomeIcon: {
            paper: "fa-envelope",
            electronic: "fa-bolt",
            default: "fa-bell"
        },
        unifiedFriendlyText: {
            paper: "Are you sure? If you went paperless, you'll receive your statements and notices faster every time, from anywhere!",
            electronic:
                "Great choice! We'll send a quick note to your email address when your statements and notices are ready to: ",
            default: "We could not determine your paperless preference",
            updateRequiredHeader: "Update Required:",
            none: {
                text: " We have adjusted the statements and notices experience.  Please click the “Update My Preferences” button below to refresh your preferences."
            },
            legacyUndeliverable:
                ' Your delivery preference requires an update. Please click the "Update My Preferences" button below to provide your preferred email address for electronic notifications.',
            undeliverable:
                ' We are unable to deliver your notifications to the email address listed above. Please click the "Update My Preferences" button below to provide your preferred email address for electronic notifications.'
        }
    },
    memberStartPage: {
        pageTitle: "Paperless Preferences",
        deliveryPreferenceLabel: "Delivery Preference: ",
        confirmUpdateNotification: "Your preferences have been updated.",
        description:
            "Manage your statement and notice delivery preference here.  Choosing electronic reduces paper!",
        termsOfServiceButtonText:
            "View Electronic Notices Disclosure and Agreement",
        optInButtonText: "Update My Preferences",
        historicPreferencesLabel: "Your Current Preferences:",

        failedToLoad: {
            message:
                "We were unable to load your preferences at this time. Please try again later or visit a branch."
        },
        pendingChangeNotification:
            "You have recently made changes that are still pending. You may be unable to submit updates at this time."
    },
    wizards: {
        optInWizard: {
            modalHeader: "Unified Opt In Wizard"
        }
    },
    optInWizard: {
        selectionPage: {
            pageTitle: "Select Your Delivery Preference",
            pageInformation:
                "We will ask for any additional details on the next page.",
            paperSelection: "Paper",
            electronicSelection: "Electronic",
            submitButton: "Confirm Changes",
            submitErrorMessage:
                "Failed to update your enrollment at this time. Please try again later or stop in at your local branch.",
            paperMarketingMessage:
                "Some Landmark accounts offer a promotional rate or bonus when you chose electronic delivery.  Choosing paper may impact your specific promotion.  Please refer to the account and truth in savings disclosure for more information.",
            loadingMessage: "Submitting your Updates..."
        },
        termsOfServicePage: {
            pageTitle: "Electronic Notices Disclosure and Agreement",
            checkLabel:
                "I have read and agree to the Electronic Notices Disclosure and Agreement.",
            submitButton: "Confirm Changes",
            submitErrorMessage:
                "Failed to submit your enrollment at this time. Please try again later or stop in at your local branch.",
            loadingMessage: "Submitting your Updates..."
        },
        emailPage: {
            pageTitle: "Tell us your email",
            pageInformation:
                "We will send you an email when a statement or notice is available.",
            emailsDontMatch: "Confirmation Email Does Not Match",
            invalidEmail: "This email is invalid. Please enter a valid email.",
            successMessage: "Press next to confirm your email."
        }
    },
    errorPages: {
        troubleLoggingIn: {
            title: "We Are Sorry",
            subText:
                "We are unable to log you in at this time. Please try again later or stop in at your local branch."
        },
        errorPage: {
            title: "We Are Sorry",
            subText:
                "We are unable to log you in at this time. Please try again later or stop in at your local branch."
        },
        NotFound404Page: {
            title: "We are Sorry",
            subText:
                "We could not find the page you are looking for. Please use the Navigation Bar to find the page you are looking for or try again later "
        }
    }
};
