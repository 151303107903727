import AsyncState from "../../shared/enumerations/AsyncState";
import IAsyncStateContainer from "../../shared/types/IAsyncStateContainer";
import { IMemberPreference } from "../../shared/models/Members";
import PreferenceTable from "./PreferenceTable";
import React, { FC } from "react";
import { translate } from "../../translate/translate";

interface IMemberPreferenceHistoryProps {
    history: IAsyncStateContainer<IMemberPreference[]>;
}

const MemberPreferenceHistory: FC<IMemberPreferenceHistoryProps> = (
    props: IMemberPreferenceHistoryProps
) => {
    if (props.history?.data && props.history.state === AsyncState.Success) {
        return (
            <PreferenceTable
                historyData={props.history.data}
                data-test="preference-table"
            />
        );
    }
    if (props.history.state === AsyncState.Loading) {
        return (
            <div data-test="member-preference-history-loading">
                <p className="mb-0 fa fa-spinner fa-spin"></p>{" "}
                {translate.associate.components.memberPreferenceHistory.loading}
            </div>
        );
    }
    if (props.history.state === AsyncState.Failure) {
        return (
            <div>
                {translate.associate.components.memberPreferenceHistory.failure}
            </div>
        );
    }
    return (
        <div>
            {translate.associate.components.memberPreferenceHistory.empty}
        </div>
    );
};

export default MemberPreferenceHistory;
