import { BUILD_STAMP, VERSION } from "./version";

const devEnvironment = {
    name: "local",
    requiredRole: "QaAssociate",
    statesToSave: ["savedState"],
    version: VERSION,
    buildTs: BUILD_STAMP,
    production: false,
    useLocalSettings: true,
    enableTimeout: false,
    collectionsAccountNoPrefix: "",
    appUrls: {
        root: "/",
        associate: "/associate",
        associateMemberView: "/associate/member-detail/:userId",
        memberlogin: "/member/login",
        memberIdentify: "/member/login/identify",
        memberOptIn: "/member/optin",
        error: "/error/:message",
        defaultError: "/error"
    },
    baseUrls: {
        vendorApi: "https://qa-vendor-api.landmarkcu.com",
        landmarkGetMedia: "https://landmarkcu.com/getmedia"
    },
    urls: {
        vendorApi: {
            auth: "/api/SAMLAssertionConsumer/authenticate",
            identify: "/api/SAMLAssertionConsumer/Identify",
            redirect: "/api/SAMLAssertionConsumer",
            associate: "/api/Associate",
            associateAgreementSearch: "/api/Associate/AgreementInformation",
            associateOptIn: "/api/associate/optin",
            getoptin: "/api/MemberPreferences/self",
            submitoptin: "/api/MemberPreferences/optin/self"
        },
        landmarkGetMedia: {
            eStatementsDisclosures: {
                uuid: "337eba11-3f06-4c24-9aaf-f41980a02259",
                url:
                    "/{uuid}/Electronic-Notices-Disclosure-and-Agreement.pdf.aspx"
            }
        }
    }
};

export default devEnvironment;
