import "./TermsPage.scss";

import AsyncState from "../../../shared/enumerations/AsyncState";
import React from "react";
import TermsAndConditions from "../../terms-and-conditions/TermsAndConditions";
import WizardButtonControl from "../../../components/wizard-button-control/WizardButtonControl";
import WizardPageContainer from "./../wizard-page-container/WizardPageContainer";
import { translate } from "../../../translate/translate";

export interface ITermsPageProps {
    submitStatus: AsyncState;
    onUpdate: (isAccepted: boolean) => any;
    userAccepts: boolean;
    goBack: () => any;
    goNext: () => any;
}

type Props = ITermsPageProps;

// eslint-disable-next-line @typescript-eslint/ban-types
class TermsPage extends React.Component<ITermsPageProps> {
    constructor(props: Props) {
        super(props);
    }

    toggleUserAccepts() {
        this.props.onUpdate(!this.props.userAccepts);
    }

    errorMessageDisplay = (): any => {
        if (this.props.submitStatus === AsyncState.Failure) {
            return (
                <div
                    className="container text-center mt-1"
                    data-test="error-message"
                >
                    <p className="text-danger">
                        {
                            translate.optInWizard.termsOfServicePage
                                .submitErrorMessage
                        }
                    </p>
                </div>
            );
        }
    };

    loadingMessageDisplay = (): any => {
        if (this.props.submitStatus === AsyncState.Loading) {
            return (
                <div
                    className="container text-center mt-1"
                    data-test="loading-message"
                >
                    <p className="mb-0 fa fa-spinner fa-spin"></p>{" "}
                    {translate.optInWizard.termsOfServicePage.loadingMessage}
                </div>
            );
        }
    };

    render() {
        return (
            <WizardPageContainer
                pageTitle={translate.optInWizard.termsOfServicePage.pageTitle}
            >
                <div className="terms-container">
                    <TermsAndConditions />
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center mr-5">
                    <div>
                        <p className="check-label mb-0 mt-2">
                            <b>
                                {
                                    translate.optInWizard.termsOfServicePage
                                        .checkLabel
                                }
                            </b>
                        </p>
                    </div>
                    <div className="pl-3">
                        <input
                            className="mt-3 check-size"
                            type="checkbox"
                            onChange={() => this.toggleUserAccepts()}
                            checked={this.props.userAccepts}
                            aria-label={
                                translate.optInWizard.termsOfServicePage
                                    .checkLabel
                            }
                        />
                    </div>
                </div>
                <WizardButtonControl
                    goBack={this.props.goBack}
                    goNext={this.props.goNext}
                    disableNext={!this.props.userAccepts}
                    customNextText={
                        translate.optInWizard.termsOfServicePage.submitButton
                    }
                />
                {this.loadingMessageDisplay()}
                {this.errorMessageDisplay()}
            </WizardPageContainer>
        );
    }
}

export default TermsPage;
