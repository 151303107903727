import React from "react";
import { getUrlForTermsOfServiceByType } from "../../services/TermsUrlService";
import { MemberOptInTypes } from "../../shared/enumerations/MemberOptInSettingsTypes";

// eslint-disable-next-line @typescript-eslint/ban-types
class TermsAndConditions extends React.Component<{}> {
    render() {
        return (
            <div className="p-4">
                <div id="content">
                    <div className="container">
                        <h2 className="mb-0">
                            Electronic Notices Disclosure and Agreement
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <div className="columns eight offset-by-two contentTable">
                        <p>
                            <a
                                href={getUrlForTermsOfServiceByType(
                                    MemberOptInTypes.universalOptIn
                                )}
                                rel="noreferrer"
                                target="_blank"
                            >
                                Download Printer-Friendly Version
                            </a>
                        </p>
                        <p>
                            <strong>
                                As used herein, the terms &quot;Landmark&quot;,
                                &quot;us,&quot; &quot;we,&quot; or
                                &quot;our&quot; and any variation thereof means
                                Landmark Credit Union, including any directors,
                                officers, employees, and any agent, independent
                                contractor, designee, or assignee the we may, at
                                our sole discretion, involve in the provision of
                                the Online Service; &quot;you&quot;,
                                &quot;your&quot; or “Member” means (1) an
                                individual or entity that is the owner of an
                                account or a party-in-interest to an account
                                (such as a grantor, beneficiary or co-trustee of
                                a trust account) or (2) an individual authorized
                                by an account owner or a party-in-interest to
                                view account information and/or effect
                                transactions in an account; “Account” has the
                                meaning attributed to it in the Account
                                Agreement as may be from time-to-time amended;
                                “Communications” means any member agreements or
                                amendments thereto, monthly billing or account
                                statements, tax statements, disclosures,
                                notices, responses to claims, transaction
                                history, privacy policies and all other
                                information related to any product, service or
                                account, including but not limited to
                                information that we are required by law to
                                provide to you in writing;. &quot;Online
                                Services&quot; means any applicable product,
                                service or functionality offered through
                                Landmark’s website and mobile applications and
                                associated documentation and information we may
                                provide through the website and mobile
                                applications either now or in the future. The
                                term also includes certain third-party digital
                                platforms as determined by us from time to time.
                                Some services are governed by additional terms
                                outlined in a separate agreement and may require
                                you to meet eligibility requirements and enroll
                                separately.
                            </strong>
                        </p>
                        <p>
                            You have the right and an option to receive certain
                            Communications in paper form. By consenting to this
                            Electronic Notices Disclosure and Agreement
                            (“Agreement”), you acknowledge that you have read,
                            understand, and agree to be bound by the terms and
                            conditions described below and consent to receive
                            Communications electronically according to the
                            process described below. This Agreement applies to
                            all Communications for Landmark products, services
                            and accounts offered or accessible through the
                            Online Service, or a mobile application or mobile
                            website used to access the Online Service, that are
                            not otherwise governed by the terms and conditions
                            of an electronic disclosure and consent that we may
                            provide to you electronically in the future.
                        </p>
                        <p>
                            Please note that you may be asked to consent to this
                            Agreement more than once during the course of our
                            relationship with you and when signing up for new
                            products or services. Your consent to receive
                            electronic Communications under this Agreement does
                            not automatically enroll you in E-Statements or
                            E-Notices. You must separately enroll to receive
                            E-Statements and E-Notices and confirm that you
                            would like to stop receiving paper account
                            statements and notices through Online Banking.
                        </p>
                        <ol>
                            <li>
                                <strong>
                                    Scope of Communications to Be Provided in
                                    Electronic Form.
                                </strong>{" "}
                                You agree that we may provide you with any
                                Communications in electronic format, and that we
                                may discontinue sending paper Communications to
                                you, unless and until you withdraw your consent
                                as described below. Your consent to receive
                                Communications in electronic form includes, but
                                is not limited to the following Communications
                                produced during the course of our relationship
                                with you and not specific to any one
                                transaction:
                                <ul>
                                    <li>
                                        This Electronic Notices Disclosure and
                                        Agreement and any amendments thereto;
                                    </li>
                                    <li>
                                        Our Online, Mobile and Electronic
                                        Banking Services User Agreement, other
                                        service, or user agreements for online
                                        or mobile access to our Online Services
                                        (such as the Account to Account Transfer
                                        Services, Popmoney Transfer Service, and
                                        Bill Payment Services), and all
                                        amendments to any of these agreements;
                                    </li>
                                    <li>
                                        Notices of change in terms for your
                                        accounts, products and services;
                                    </li>
                                    <li>
                                        All of the periodic account and activity
                                        and billing statements, disclosures and
                                        notices we provide to you concerning
                                        your Landmark accounts and transactions;
                                    </li>
                                    <li>
                                        Any loan agreements and related
                                        documentation and disclosures;
                                    </li>
                                    <li>
                                        Any notice or disclosure regarding
                                        insufficient funds, account overdrafts,
                                        negative accounts and past due payments
                                        and fees or assessments of any kind,
                                        including late fees, overdraft fees,
                                        over limit fees, and returned item fees;
                                    </li>
                                    <li>
                                        Safe deposit box rental and home equity
                                        credit line renewal notices;
                                    </li>
                                    <li>Certificate maturity notices;</li>
                                    <li>Privacy policies;</li>
                                    <li>
                                        Year-end tax statements for dividends
                                        and mortgage interest paid;
                                    </li>
                                    <li>Responses to claims; and</li>
                                    <li>
                                        All other information related to the
                                        products, services, or accounts,
                                        including but not limited to information
                                        and disclosures that we are required by
                                        law to provide to you in writing.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>
                                    Method of Providing Communications to You in
                                    Electronic Form.
                                </strong>{" "}
                                All Communications that we provide to you in
                                electronic form will be provided by one or more
                                of the following methods: (i) via email, (ii) by
                                access to a website, including our mobile
                                websites, that we will designate in an email
                                notice we send to you at the time the
                                information is available, (iii) to the extent
                                permissible by law, by access to a website,
                                including our mobile websites, that we will
                                generally designate in advance for such purpose,
                                (iv) via our mobile applications, or (v) by
                                requesting you download a PDF file containing
                                the Communication. Communication by email is
                                considered to be sent at the time that it is
                                directed by us to the email address you provided
                                and will be deemed to have been received by you
                                when we send it to you, whether or not you
                                receive the email. We will notify you by email
                                at the email address you have provided or by a
                                message within Online Banking or Mobile Banking
                                when new Communications are available for
                                viewing. You agree that these are reasonable
                                procedures for sending and receiving
                                Communications electronically.
                            </li>
                            <li>
                                <strong>How to Withdrawal Consent.</strong>You
                                may withdraw your consent to receive
                                Communications in electronic form by contacting
                                us at (262) 796-4500, or (800) 871-2110 if
                                outside the Milwaukee Metro area, or, for
                                E-Statements and E-Notices, by changing your
                                delivery preferences within the Online Banking.
                                <br />
                                <br />
                                There are no fees for choosing to withdraw your
                                consent and begin receiving documents in paper
                                form. However, withdrawing your consent to
                                receive statements electronically for your
                                Premium Checking Account may result in reduction
                                of APY that you earn on that account. Please
                                refer to your Account Agreement for additional
                                information. Additionally, withdrawing your
                                consent to receive Communications electronically
                                may make you ineligible to receive some of the
                                Online Services.
                                <br />
                                <br />
                                At our option, we may treat your provision of an
                                invalid e-mail address, or the subsequent
                                malfunction of a previously valid e-mail
                                address, as a withdrawal of your consent to
                                receive electronic Communications. Any
                                withdrawal of your consent to receive electronic
                                Communications will be effective only after we
                                have a reasonable period of time to process your
                                withdrawal.
                            </li>
                            <li>
                                <strong>How to Update Your Records.</strong> It
                                is your responsibility to provide us with true,
                                accurate and complete email address, contact,
                                and other information related to this Agreement
                                and your account(s), and to maintain and update
                                promptly any changes in this information. You
                                can update information (such as your email
                                address) through Online Banking, at any of our
                                branch locations or by contacting us at (262)
                                796-4500, or (800) 871-2110 if outside the
                                Milwaukee Metro area. If you do not update your
                                email address, your Communications will still be
                                available to you through Online Banking,
                                however, you will no longer receive an email
                                notification until you update your email
                                address.
                            </li>
                            <li>
                                <strong>
                                    Hardware and Software Requirements.
                                </strong>{" "}
                                To receive an electronic copy of the
                                Communications you must have the following
                                equipment and software:
                                <ul>
                                    <li>
                                        A personal computer or other device
                                        which is capable of accessing the
                                        Internet.
                                    </li>
                                    <li>
                                        A printer if you wish to print out and
                                        retain records on paper and electronic
                                        storage if you wish to retain records in
                                        electronic form.
                                    </li>
                                    <li>
                                        An email account and email software
                                        capable of reading and responding to
                                        your email.
                                    </li>
                                    <li>
                                        An Internet web browser which is capable
                                        of supporting 128-bit SSL encrypted
                                        communications, which requires a certain
                                        version of a web browser and your system
                                        or device must have 128-bit SSL
                                        encryption software. You can find the
                                        most recent recommended browser versions{" "}
                                        <a href="https://landmarkcu.com/online-banking/help/">
                                            here
                                        </a>
                                        . Your access to this page verifies that
                                        your browser and encryption
                                        software/device meet these requirements.
                                    </li>
                                    <li>
                                        Software which permits you to receive
                                        and access Portable Document Format or
                                        “PDF” files, such as Adobe Acrobat
                                        Reader® version 8.0 and above (available
                                        to download at
                                        http://www.adobe.com/products/acrobat/readstep2.html).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Requesting Paper Copies.</strong> You
                                should not expect to receive a paper copy of any
                                Communication, unless you request it or we
                                otherwise deem it appropriate to do so. You can
                                obtain a paper copy of an electronic
                                Communication by printing or downloading it
                                yourself or by requesting that we mail you a
                                paper copy, provided that such request is made
                                within a reasonable time after we first provided
                                the electronic Communication to you. To request
                                a paper copy, contact us by telephone at (262)
                                796-4500, or (800) 871-2110 if outside the
                                Milwaukee Metro area, or by logging in to the
                                Online Service and sending a request using your
                                Secure Inbox. We may charge you a reasonable
                                service charge, for the delivery of paper copies
                                of any Communication provided to you
                                electronically pursuant to this authorization.
                                For copy fees that may apply, please refer to
                                Landmark’s Fee Schedule at{" "}
                                <a href="https://landmarkcu.com/calculators-resources/resources/fee-schedule/">
                                    www.landmarkcu.com/fee-schedule
                                </a>
                                . We reserve the right, but assume no
                                obligation, to provide a paper (instead of
                                electronic) copy of any Communication that you
                                have authorized us to provide to you
                                electronically.
                            </li>
                            <li>
                                <strong>Communications in Writing.</strong> All
                                Communications in either electronic or paper
                                format from us to you will be considered
                                &quot;in writing.&quot; You should print or
                                download for your records a copy of this
                                Agreement and any other Communication that is
                                important to you. You will be able to access
                                your documents online for a period of,
                                generally, 3 to 24 months depending on the
                                document and the process or transaction that it
                                relates to.
                            </li>
                            <li>
                                <strong>Federal Law.</strong> You acknowledge
                                and agree that your consent to electronic
                                Communications is being provided in connection
                                with a transaction affecting interstate commerce
                                that is subject to the federal Electronic
                                Signatures in Global and National Commerce Act
                                (the “Act”), and that you and we both intend
                                that the Act apply to the fullest extent
                                possible to validate our ability to conduct
                                business with you by electronic means.
                            </li>
                            <li>
                                <strong>Termination/Changes.</strong> We reserve
                                the right, in our sole discretion, to
                                discontinue the provision of your electronic
                                Communications, or to terminate or change the
                                terms and conditions on which we provide
                                electronic Communications. We will provide you
                                with notice of any such termination or change as
                                required by law.
                            </li>
                        </ol>
                    </div>
                </div>
                <hr />
                <p className="ml-3">Effective April 2021</p>
            </div>
        );
    }
}

export default TermsAndConditions;
