import { ActionCreator, Dispatch } from "redux";
import AsyncState from "../shared/enumerations/AsyncState";
import IAsyncStateContainer from "../shared/types/IAsyncStateContainer";
import { IMemberOptInInformation } from "../reducers/MemberOptInReducer";
import { IMemberOptInState } from "../reducers/MemberOptInReducer";
import { ThunkAction } from "redux-thunk";
import { getMemberOptInPreference } from "../services/MemberOptInService";

export enum MemberOptInActionTypes {
    GET_OPTIN_PREFERENCES = "GET_OPTIN_PREFERENCES"
}

export interface IMemberOptInGetPreferences {
    type: MemberOptInActionTypes.GET_OPTIN_PREFERENCES;
    memberOptinInformation: IAsyncStateContainer<IMemberOptInInformation>;
}

export const getOptInPreferences: ActionCreator<ThunkAction<
    Promise<any>,
    IMemberOptInState,
    null,
    IMemberOptInGetPreferences
>> = () => {
    return async (dispatch: Dispatch<IMemberOptInGetPreferences>) => {
        dispatch({
            type: MemberOptInActionTypes.GET_OPTIN_PREFERENCES,
            memberOptinInformation: { state: AsyncState.Loading }
        });

        const memberOptinInformation = await getMemberOptInPreference();

        dispatch({
            type: MemberOptInActionTypes.GET_OPTIN_PREFERENCES,
            memberOptinInformation: memberOptinInformation
        });
    };
};

export type MemberOptInActions = IMemberOptInGetPreferences;
