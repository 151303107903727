import AsyncState from "../shared/enumerations/AsyncState";
import Axios from "axios";
import Configuration from "../shared/configuration/Configuration";
import { IAssociateOptInRequest } from "../shared/models/RequestBody";
import IAsyncStateContainer from "../shared/types/IAsyncStateContainer";
import { IMemberOptInDetail } from "../shared/models/Members";
import {
    IMemberOptInSettings,
    IMemberOptInSettingsWithAgreement
} from "../reducers/MemberOptInReducer";
import { OptInDeliveryMethods } from "../shared/enumerations/OptInDeliveryMethods";
import moment from "moment";
import { Mutable } from "utility-types";

export const queryMemberList = async (
    accountId: string
): Promise<IAsyncStateContainer<IMemberOptInSettingsWithAgreement[]>> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.associateAgreementSearch +
            "/" +
            accountId;

        const res = await Axios.get(url, { withCredentials: true });

        return {
            state: AsyncState.Success,
            data: res.data
        };
    } catch (err) {
        if (err.response?.status == 404) {
            return {
                state: AsyncState.Success,
                data: undefined
            };
        }

        return {
            state: AsyncState.Failure
        };
    }
};

export const getMemberDetail = async (
    userId: string
): Promise<IAsyncStateContainer<IMemberOptInDetail>> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.associate +
            "/" +
            userId;

        const res = await Axios.get(url, { withCredentials: true });

        if (res.data) {
            return {
                state: AsyncState.Success,
                data: sortMemberDetailsHistory(
                    convertMemberDetailsDates(res.data)
                )
            };
        }

        return {
            state: AsyncState.Success,
            data: res.data
        };
    } catch (err) {
        if (err.response?.status == 404) {
            return {
                state: AsyncState.Success,
                data: undefined
            };
        }

        return {
            state: AsyncState.Failure
        };
    }
};

export const sortMemberDetailsHistory = (
    memberOptInDetails: IMemberOptInDetail
) => {
    if (memberOptInDetails.history) {
        return {
            ...memberOptInDetails,
            history: memberOptInDetails.history.sort((a, b) => {
                if (a.created > b.created) {
                    return -1;
                }
                if (a.created < b.created) {
                    return 1;
                }
                return 0;
            })
        };
    }
    return memberOptInDetails;
};

/*
 * The dates arrive as strings, you have to convert them to moment...
 * Do any additional conversion here...
 */
export const convertMemberDetailsDates = (
    memberOptInDetails: IMemberOptInDetail
) => {
    if (memberOptInDetails.history) {
        memberOptInDetails.history.forEach((pref) => {
            pref.created = moment.utc(pref.created);
        });
    }

    const optinSettings = memberOptInDetails.settings
        .memberOptinSettings as Mutable<IMemberOptInSettings> | null;
    if (optinSettings) {
        const dateIfDefined = (date?: string | Date): Date | undefined => {
            if (date === undefined) return date;
            if (typeof date !== "string") return date;

            return new Date(date);
        };

        optinSettings.unifiedOptInEmailFirstSent = dateIfDefined(
            optinSettings.unifiedOptInEmailFirstSent
        );
        optinSettings.unifiedOptInEmailLastSent = dateIfDefined(
            optinSettings.unifiedOptInEmailLastSent
        );

        optinSettings.eNotificationsEmailFirstSent = dateIfDefined(
            optinSettings.eNotificationsEmailFirstSent
        );
        optinSettings.eNotificationsEmailLastSent = dateIfDefined(
            optinSettings.eNotificationsEmailLastSent
        );

        optinSettings.eStatementsEmailFirstSent = dateIfDefined(
            optinSettings.eStatementsEmailFirstSent
        );
        optinSettings.eStatementsEmailLastSent = dateIfDefined(
            optinSettings.eStatementsEmailLastSent
        );
    }

    return memberOptInDetails;
};

export const submitMemberOptInUpdate = async (
    userId: number,
    newDeliveryMethod: OptInDeliveryMethods
): Promise<AsyncState> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.associateOptIn;

        if (newDeliveryMethod == OptInDeliveryMethods.Electronic) {
            return AsyncState.Failure;
        }

        const body: IAssociateOptInRequest = {
            userId: userId,
            value: newDeliveryMethod.toString(),
            LegalReference: moment().format("YYYY-MM-DDTHH:mm:ss")
        };

        await Axios.post(url, body, { withCredentials: true });

        return AsyncState.Success;
    } catch (err) {
        return AsyncState.Failure;
    }
};

export const submitMemberEmailUpdate = async (
    userId: number,
    currentUnifiedDeliveryMethod: string,
    newEmail: string
): Promise<AsyncState> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.associateOptIn;

        if (currentUnifiedDeliveryMethod != OptInDeliveryMethods.Electronic) {
            return AsyncState.Failure;
        }

        const body: IAssociateOptInRequest = {
            userId: userId,
            value: OptInDeliveryMethods.Electronic.toString(),
            email: newEmail,
            LegalReference: moment().format("YYYY-MM-DDTHH:mm:ss")
        };

        await Axios.post(url, body, { withCredentials: true });

        return AsyncState.Success;
    } catch (err) {
        return AsyncState.Failure;
    }
};
