import DisplayBar, {
    DisplayBarColor
} from "../../components/display-bar/DisplayBar";
import {
    getHistoricDisplayDetails,
    getUnifiedDisplayDetails,
    IMemberOptInDisplayDetails,
    isOptedIn
} from "../../services/MemberOptInService";

import AsyncState from "../../shared/enumerations/AsyncState";
import ConfirmationNotification from "./confirmation-notification/ConfirmationNotification";
import HistoricPreferenceDisplay from "./historic-preference-display/HistoricPreferenceDisplay";
import { IAppState } from "../../store/Store";
import IAsyncStateContainer from "../../shared/types/IAsyncStateContainer";
import { IMemberOptInFormData } from "../../reducers/MemberOptInFormReducer";
import {
    IMemberOptInInformation,
    IMemberOptInSettings
} from "../../reducers/MemberOptInReducer";
import { MemberOptInTypes } from "../../shared/enumerations/MemberOptInSettingsTypes";
import OpenWizardButtons from "./open-wizard-buttons/OpenWizardButtons";
import React from "react";
import { RouteComponentProps } from "react-router";
import { RouteProps } from "react-router-dom";
import Routes from "./../../shared/Routes";
import UnifiedPreferenceDisplay from "./unified-preference-display/UnifiedPreferenceDisplay";
import { connect } from "react-redux";
import { getOptInPreferences } from "../../actions/MemberOptInActions";
import { getUrlForTermsOfServiceByType } from "../../services/TermsUrlService";
import { translate } from "../../translate/translate";
import WarningNotification from "../../components/warning-notification/WarningNotification";
import { PreferenceUpdateCallToAction } from "./PreferenceUpdateCallToAction";

interface IPropsFromState {
    memberOptinInformation: IAsyncStateContainer<IMemberOptInInformation>;
    memberOptInRequestState: IMemberOptInFormData;
}
interface IPropsFromDispatch {
    getOptInPreferences: () => any;
}

const mapStateToProps = (appState: IAppState): IPropsFromState => {
    return {
        memberOptinInformation:
            appState.memberOptInState.memberOptinInformation,
        memberOptInRequestState:
            appState.memberOptInRequestState.memberOptInFormData
    };
};

const mapDispatchToProps: IPropsFromDispatch = {
    getOptInPreferences: getOptInPreferences
};

type Props = RouteProps &
    IPropsFromDispatch &
    IPropsFromState &
    RouteComponentProps;

// eslint-disable-next-line @typescript-eslint/ban-types
class MemberStartPage extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.getOptInPreferences();
    }

    hasStatementNoticePreference = (
        memberOptInSettings: IMemberOptInSettings
    ): boolean => {
        return (
            isOptedIn(memberOptInSettings, MemberOptInTypes.eStatements) ||
            isOptedIn(memberOptInSettings, MemberOptInTypes.eNotifications)
        );
    };

    hasUnifiedPreference = (
        memberOptInSettings: IMemberOptInSettings
    ): boolean => {
        return isOptedIn(memberOptInSettings, MemberOptInTypes.universalOptIn);
    };

    //TODO We can definitely create 1 more wrapping component for all of this
    render() {
        if (
            this.props.memberOptinInformation.state === AsyncState.Success &&
            this.props.memberOptinInformation.data?.memberOptinSettings
        ) {
            const hasUnifiedPreference = this.hasUnifiedPreference(
                this.props.memberOptinInformation.data.memberOptinSettings
            );
            const unifiedDisplayDetails = getUnifiedDisplayDetails(
                this.props.memberOptinInformation.data.memberOptinSettings
            );
            const historicDisplayDetails = getHistoricDisplayDetails(
                this.props.memberOptinInformation.data.memberOptinSettings
            );

            return (
                <div className="p-2 page-view container">
                    <WarningNotification
                        shouldDisplay={
                            this.props.memberOptinInformation.data
                                .hasPendingUpdates
                        }
                        message={
                            translate.memberStartPage.pendingChangeNotification
                        }
                    />
                    <ConfirmationNotification
                        displayText={
                            translate.memberStartPage.confirmUpdateNotification
                        }
                        submitStatus={
                            this.props.memberOptInRequestState.submitStatus
                        }
                    />
                    <h2>{translate.memberStartPage.pageTitle}</h2>
                    <p>{translate.memberStartPage.description}</p>
                    <HistoricPreferenceDisplay
                        displayDetails={
                            Object.values(
                                historicDisplayDetails
                            ) as IMemberOptInDisplayDetails[]
                        }
                        showHistoricDetails={
                            !this.hasUnifiedPreference(
                                this.props.memberOptinInformation.data
                                    .memberOptinSettings
                            ) &&
                            this.hasStatementNoticePreference(
                                this.props.memberOptinInformation.data
                                    .memberOptinSettings
                            )
                        }
                    />
                    <UnifiedPreferenceDisplay
                        unifiedDisplayDetails={unifiedDisplayDetails}
                        hasUnifiedPreference={hasUnifiedPreference}
                    />
                    <PreferenceUpdateCallToAction
                        hasUnifiedPreference={hasUnifiedPreference}
                        undeliverable={{
                            unified: unifiedDisplayDetails.undeliverable,
                            eNotifications:
                                historicDisplayDetails.eNotifications
                                    ?.undeliverable,
                            eStatements:
                                historicDisplayDetails.eStatements
                                    ?.undeliverable
                        }}
                    />
                    <OpenWizardButtons
                        openTerms={() => {
                            window.open(
                                getUrlForTermsOfServiceByType(
                                    MemberOptInTypes.eStatements
                                )
                            );
                        }}
                        openWizard={() =>
                            this.props.history.push(Routes.memberOptIn)
                        }
                        data-test="button-component"
                    />
                </div>
            );
        }

        if (this.props.memberOptinInformation.state === AsyncState.Success) {
            return (
                <div
                    data-test="no-data-found"
                    className="p-2 page-view container"
                >
                    <ConfirmationNotification
                        submitStatus={
                            this.props.memberOptInRequestState.submitStatus
                        }
                        displayText={
                            translate.memberStartPage.confirmUpdateNotification
                        }
                    />
                    <h2>{translate.memberStartPage.pageTitle}</h2>
                    <p>{translate.memberStartPage.description}</p>
                    <DisplayBar color={DisplayBarColor.Detail} icon="" center>
                        <p className="mb-0 text-center">
                            {translate.memberStartPage.deliveryPreferenceLabel}
                            <span className="font-weight-bold text-info">
                                {translate.preferences.deliveryMethodText.paper}
                            </span>
                        </p>
                        <p className="mb-0 text-center">
                            {translate.preferences.unifiedFriendlyText.paper}
                        </p>
                    </DisplayBar>
                    <OpenWizardButtons
                        openTerms={() => {
                            window.open(
                                getUrlForTermsOfServiceByType(
                                    MemberOptInTypes.eStatements
                                )
                            );
                        }}
                        openWizard={() =>
                            this.props.history.push(Routes.memberOptIn)
                        }
                    />
                </div>
            );
        }

        if (this.props.memberOptinInformation.state === AsyncState.Loading) {
            return (
                <div
                    data-test="loading-component"
                    className="p-2 page-view container"
                >
                    <ConfirmationNotification
                        submitStatus={
                            this.props.memberOptInRequestState.submitStatus
                        }
                        displayText={
                            translate.memberStartPage.confirmUpdateNotification
                        }
                    />
                    <h2>{translate.memberStartPage.pageTitle}</h2>
                    <p>{translate.memberStartPage.description}</p>
                    <div className="d-flex justify-content-center align-content-center mt-3">
                        <div
                            className="spinner-border text-primary"
                            role="status"
                            data-test="spinner"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="text-primary ml-3">
                            <p className="mb-0">Loading Preferences</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="p-2 page-view container" data-test="fail-component">
                <ConfirmationNotification
                    submitStatus={
                        this.props.memberOptInRequestState.submitStatus
                    }
                    displayText={
                        translate.memberStartPage.confirmUpdateNotification
                    }
                />
                <h2>{translate.memberStartPage.pageTitle}</h2>
                <p>{translate.memberStartPage.description}</p>
                <DisplayBar color={DisplayBarColor.Detail} icon="" center>
                    {translate.memberStartPage.failedToLoad.message}
                </DisplayBar>
            </div>
        );
    }
}

export const _MemberStartPage = MemberStartPage;
// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<IPropsFromState, IPropsFromDispatch, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
)(MemberStartPage);
