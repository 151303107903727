import { RouteComponentProps, RouteProps } from "react-router-dom";
import {
    clearMemberOptInForm,
    submitMemberOptInForm,
    submitMemberOptOutForm,
    toggleMemberAcceptance,
    updateMemberConfirmationEmail,
    updateMemberOptInEmail
} from "../../actions/MemberOptInFormActions";

import AsyncState from "../../shared/enumerations/AsyncState";
import { IAppState } from "../../store/Store";
import IAsyncStateContainer from "../../shared/types/IAsyncStateContainer";
import { IMemberOptInFormData } from "../../reducers/MemberOptInFormReducer";
import { IMemberOptInInformation } from "../../reducers/MemberOptInReducer";
import React from "react";
import Routes from "../../shared/Routes";
import SelectFlowPage from "./select-flow-page/SelectFlowPage";
import TermsPage from "./terms-page/TermsPage";
import WarningInfoPage from "../../components/warning-info-page/WarningInfoPage";
import { connect } from "react-redux";
import { getOptInPreferences } from "../../actions/MemberOptInActions";
import WarningNotification from "../../components/warning-notification/WarningNotification";
import { translate } from "../../translate/translate";

interface IPropsFromState {
    memberOptInRequestState: IMemberOptInFormData;
    memberOptinInformation: IAsyncStateContainer<IMemberOptInInformation>;
}
interface IPropsFromDispatch {
    getOptInPreferences: () => any;
    toggleMemberAcceptance: () => any;
    updateMemberOptInEmail: (newValue: string) => any;
    updateMemberConfirmationEmail: (newValue: string) => any;
    clearMemberOptInForm: () => any;
    submitMemberOptInForm: () => any;
    submitMemberOptOutForm: () => any;
}

export enum UnifiedWizardFlows {
    Electronic,
    Paper,
    Unselected
}
interface IUnifiedWizardState {
    currentPage: UnifiedWizardPages;
    selectedFlow: UnifiedWizardFlows;
}

const mapStateToProps = (appState: IAppState): IPropsFromState => {
    return {
        memberOptInRequestState:
            appState.memberOptInRequestState.memberOptInFormData,
        memberOptinInformation: appState.memberOptInState.memberOptinInformation
    };
};

const mapDispatchToProps: IPropsFromDispatch = {
    getOptInPreferences: getOptInPreferences,
    toggleMemberAcceptance: toggleMemberAcceptance,
    updateMemberOptInEmail: updateMemberOptInEmail,
    updateMemberConfirmationEmail: updateMemberConfirmationEmail,
    clearMemberOptInForm: clearMemberOptInForm,
    submitMemberOptInForm: submitMemberOptInForm,
    submitMemberOptOutForm: submitMemberOptOutForm
};

type Props = IPropsFromDispatch &
    IPropsFromState &
    RouteProps &
    RouteComponentProps;

export enum UnifiedWizardPages {
    selectflow = "selectflow",
    termsPage = "terms",
    updateAddEmail = "email",
    optOutConfirmation = "optout"
}

class UnifiedWizard extends React.Component<Props, IUnifiedWizardState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentPage: UnifiedWizardPages.selectflow,
            selectedFlow: UnifiedWizardFlows.Unselected
        };
        this.submitOptIn.bind(this);
        this.submitOptOut.bind(this);
    }

    async componentDidMount() {
        this.props.clearMemberOptInForm();

        if (this.props.memberOptinInformation.state == AsyncState.Inactive) {
            await this.props.getOptInPreferences();
        }

        if (this.props.memberOptinInformation.data?.memberOptinSettings) {
            switch (
                this.props.memberOptinInformation.data.memberOptinSettings
                    .universalOptIn
            ) {
                case "Electronic":
                    this.setState({
                        selectedFlow: UnifiedWizardFlows.Electronic
                    });
                    return;
                case "Paper":
                    this.setState({ selectedFlow: UnifiedWizardFlows.Paper });
                    return;
                default:
                    this.setState({
                        selectedFlow: UnifiedWizardFlows.Unselected
                    });
            }
        }
    }

    async submitOptIn() {
        await this.props.submitMemberOptInForm().then(() => {
            if (
                this.props.memberOptInRequestState.submitStatus ==
                AsyncState.Success
            ) {
                this.props.history.push(Routes.root);
            }
        });
    }

    async submitOptOut() {
        await this.props.submitMemberOptOutForm().then(() => {
            if (
                this.props.memberOptInRequestState.submitStatus ==
                AsyncState.Success
            ) {
                this.props.history.push(Routes.root);
            }
        });
    }

    selectPage() {
        switch (this.state.currentPage) {
            case UnifiedWizardPages.selectflow:
                return (
                    <SelectFlowPage
                        selectedFlow={this.state.selectedFlow}
                        submitStatus={
                            this.props.memberOptInRequestState.submitStatus
                        }
                        goBack={() => this.props.history.goBack()}
                        goNextOptIn={() => {
                            this.setState({
                                currentPage: UnifiedWizardPages.termsPage
                            });
                        }}
                        goNextOptOut={() => this.submitOptOut()}
                        unifiedEmailAddress={
                            this.props.memberOptInRequestState.unifiedOptinEmail
                        }
                        confirmationEmailAddress={
                            this.props.memberOptInRequestState.confirmationEmail
                        }
                        handleEmailInputChange={(newValue) => {
                            this.props.updateMemberOptInEmail(newValue);
                        }}
                        handleEmailConfirmationChange={(newValue) => {
                            this.props.updateMemberConfirmationEmail(newValue);
                        }}
                        updateSelectedFlow={(newFlow) =>
                            this.setState({ selectedFlow: newFlow })
                        }
                    />
                );

            case UnifiedWizardPages.termsPage:
                return (
                    <TermsPage
                        submitStatus={
                            this.props.memberOptInRequestState.submitStatus
                        }
                        userAccepts={
                            this.props.memberOptInRequestState
                                .memberAcceptedTerms
                        }
                        onUpdate={() => {
                            this.props.toggleMemberAcceptance();
                        }}
                        goBack={() => {
                            this.setState({
                                currentPage: UnifiedWizardPages.selectflow
                            });
                        }}
                        goNext={() => this.submitOptIn()}
                    />
                );

            default:
                return <WarningInfoPage title="Page Not Found" />;
        }
    }

    render() {
        return (
            <div>
                <div className="container pt-2">
                    <WarningNotification
                        shouldDisplay={
                            this.props.memberOptinInformation.data
                                ?.hasPendingUpdates
                        }
                        message={
                            translate.memberStartPage.pendingChangeNotification
                        }
                    />
                </div>
                {this.selectPage()}
            </div>
        );
    }
}

export const _UnifiedWizard = UnifiedWizard;
// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<IPropsFromState, IPropsFromDispatch, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
)(UnifiedWizard);
