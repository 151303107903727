import AsyncState from "../shared/enumerations/AsyncState";
import Axios from "axios";
import Configuration from "../shared/configuration/Configuration";
import CookieService from "./CookieService";
import moment from "moment";

const DATE_OF_BIRTH_TEMPLATE = "YYYY-MM-DD";

export const loginMember = async (
    username: string,
    password: string
): Promise<AsyncState> => {
    try {
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.auth;
        const res = await Axios.post(
            url,
            { password: password, username: username },
            { withCredentials: true }
        );

        if (
            (res.status == 200 || res.status == 204) &&
            CookieService.hasAuthCookie()
        ) {
            return AsyncState.Success;
        }

        return AsyncState.Failure;
    } catch (err) {
        return AsyncState.Failure;
    }
};

export const identifyMember = async (
    socialSecurity: string,
    DateOfBirth: string
): Promise<AsyncState> => {
    try {
        // TODO , confirm the format this date arrives in and then custom parse it so the test stops complaining.
        const formattedDate = moment(DateOfBirth)
            .format(DATE_OF_BIRTH_TEMPLATE)
            .toString();
        const url =
            Configuration.Get.baseUrls.vendorApi +
            Configuration.Get.urls.vendorApi.identify;
        const res = await Axios.post(
            url,
            {
                SocialSecurityNumber: socialSecurity,
                DateOfBirth: formattedDate
            },
            { withCredentials: true }
        );

        if (
            (res.status == 200 || res.status == 204) &&
            CookieService.hasAuthCookie()
        ) {
            return AsyncState.Success;
        }

        return AsyncState.Failure;
    } catch (err) {
        return AsyncState.Failure;
    }
};
