import "./Footer.scss";
import {BUILD_STAMP, VERSION} from "../../environments/version";
import React from "react";
import  watermark  from "../../assets/light-house-corner-watermark.png";


const Footer: React.FC = () => {
    const versionHint = VERSION.version + "-" + BUILD_STAMP;
    const currentYear = new Date().getFullYear();
    return (
        <div className="position">
        <div className="watermark-outer">
            <div className="watermark-inner">
                <img src={watermark} className="watermark-primary" alt="" />
            </div>
        </div>
        <footer className="py-3 bg-dark">
            <div className="container">
                <p className="m-0 text-center text-white"
                   title={versionHint}>Copyright &copy; {currentYear} Landmark Credit Union</p>
            </div>
        </footer>
        </div>
    );
};

export default Footer;
