import uatEnvironment from "./environment.uat";

const prodEnvironment = {
    ...uatEnvironment,
    name: "prod",
    requiredRole: "Associate",
    baseUrls: {
        vendorApi: "https://vendor-api.landmarkcu.com",
        landmarkGetMedia: "https://landmarkcu.com/getmedia"
    }
};

export default prodEnvironment;
