import "./ValidatedInput.scss";

import { IValidationState } from "../../shared/validators/IValidationState";
import React from "react";
import { ValidationStatus } from "../../shared/enumerations/ValidationStatus";

interface IValidatedInputProps {
    validationState: IValidationState;
}

const ValidatedInput: React.FC<IValidatedInputProps> = (props) => {
    if (props.validationState.status === ValidationStatus.InValid) {
        return (
            <div className="validated-input invalid">
                {props.children}
                <p className="m-0 text-danger">
                    {props.validationState.message}
                </p>
            </div>
        );
    } else if (props.validationState.status === ValidationStatus.Valid) {
        return (
            <div className="validated-input valid">
                {props.children}
                <p className="m-0 text-success">
                    {props.validationState.message}
                </p>
            </div>
        );
    } else if (props.validationState.status === ValidationStatus.InProgress) {
        return (
            <div className="validated-input inprogress">
                {props.children}
                <p className="m-0">{props.validationState.message}</p>
            </div>
        );
    } else
        return (
            <div>
                {props.children}
                <p>{props.validationState.message}</p>
            </div>
        );
};

export default ValidatedInput;
