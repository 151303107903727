import {Redirect, RouteProps,} from "react-router-dom";

import AsyncState from "../../shared/enumerations/AsyncState";
import CookieService from "./../../services/CookieService";
import React from "react";
import { loginMember } from "./../../services/LoginService";
import {tryUpdateStateWithInput} from "../../services/InputService";

export interface MemberLoginState {
    loginSubmitted: boolean;
    loginStatus: AsyncState;
    username: string;
    password: string;
}

class MemberLogin extends React.Component<RouteProps, MemberLoginState> {
    state = { loginSubmitted: false, username:"", password:"", loginStatus: AsyncState.Inactive}
    

    componentDidUpdate(prevProps: RouteProps, prevState: MemberLoginState){
        if(prevState.loginStatus != this.state.loginStatus && this.state.loginStatus == AsyncState.Failure){
           this.setState({
               loginSubmitted : false
           });
        }
    }

    handleInputChange(name: string, newValue: any) {
        this.setState(
            tryUpdateStateWithInput<MemberLoginState>(this.state, name, newValue)
        );
    }

    async submitForAuth() {
        if (this.state.username == "" || this.state.password == "") return;

        this.setState({
            loginSubmitted: true,
            loginStatus: AsyncState.Loading
        });

        const loginState: AsyncState = await loginMember(this.state.username, this.state.password);

        this.setState({
            loginStatus: loginState,
            username: "",
            password: ""
        });
    }

    shouldDisableSubmit = () => {
        return (this.state.username=="" || this.state.password=="" || this.state.loginSubmitted);
    }

    render() { 

        if (this.state.loginStatus === AsyncState.Success || CookieService.hasAuthCookie()) {
            return(<Redirect to="/" data-test="redirect-root"/>);
        } 
        
        return(
            <div className="container" data-test="member-login">
                <div className="row">
                    <div className="col d-flex justify-content-center ">
                        <div className="d-flex flex-column px">
                        <div className="shadow-sm pb-5 px-5 pt-1 rounded mt-5">
                            <h3 className="text-primary">Member Login</h3>
                            <div className="d-flex justify-content-center my-1">
                                {(this.state.loginStatus === AsyncState.Loading)?
                                    <div className="spinner-border text-primary" role="status" data-test="spinner">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                : (this.state.loginStatus === AsyncState.Failure)?
                                    <div>
                                        <p data-test="login-error-message" className="text-danger text-center">invalid credentials</p>
                                   </div>
                               : null}
                                
                            </div>
                            <form>
                            <div>
                                <p className="mb-0">Username</p>
                                <input autoComplete="username" className="form-input mb-1" placeholder={"Username"} value={this.state.username} onChange={(event) => this.handleInputChange("username", event.target.value)}/>
                            </div>
                            <div>
                                <p className="mb-0">Password</p>
                                <input autoComplete="password" type="password" className="form-input mb-2" placeholder={"Password"} value={this.state.password} onChange={(event) => this.handleInputChange("password", event.target.value)}/>
                            </div>
                            </form>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-end small">Login Assistance</div>
                                    <div><button className="btn btn-primary" disabled={this.shouldDisableSubmit()} onClick={()=>{this.submitForAuth();}} data-test="login-button">Login</button></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
 
export default MemberLogin;