import React from "react";
import DisplayBar, {
    DisplayBarColor
} from "../../components/display-bar/DisplayBar";
import { translate } from "../../translate/translate";

export interface IPreferenceUpdateCallToActionProps {
    undeliverable: {
        unified?: boolean;
        eStatements?: boolean;
        eNotifications?: boolean;
    };
    hasUnifiedPreference?: boolean;
}

const CallToAction = ({
    message,
    color
}: {
    readonly message: string;
    readonly color: DisplayBarColor;
}) => (
    <DisplayBar color={color} icon="" center>
        <p className="mb-0 text-center">
            <b>
                {translate.preferences.unifiedFriendlyText.updateRequiredHeader}
            </b>{" "}
            {" " + message}
        </p>
    </DisplayBar>
);

export const PreferenceUpdateCallToAction: React.FC<
    IPreferenceUpdateCallToActionProps
> = ({ undeliverable, hasUnifiedPreference }) => {
    if (hasUnifiedPreference === true) {
        if (undeliverable.unified) {
            return (
                <CallToAction
                    color={DisplayBarColor.Error}
                    message={
                        translate.preferences.unifiedFriendlyText.undeliverable
                    }
                />
            );
        }

        return null;
    }

    const anyUndeliverable =
        undeliverable.eStatements || undeliverable.eNotifications;
    const callToAction = anyUndeliverable
        ? translate.preferences.unifiedFriendlyText.legacyUndeliverable
        : translate.preferences.unifiedFriendlyText.none.text;

    return (
        <div data-test="no-unified-preference">
            <CallToAction
                color={
                    anyUndeliverable
                        ? DisplayBarColor.Error
                        : DisplayBarColor.Detail
                }
                message={callToAction}
            />
        </div>
    );
};
